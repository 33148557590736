import React, { FC, useContext, useState } from "react";
import { MultiSelector, MultiSelectorContent, MultiSelectorInput, MultiSelectorList, MultiSelectorItem, MultiSelectorTrigger } from "../ui/multi-selector";
import { getFlagEmoji } from "@/lib/utils";
import { DataContext } from "@/context/dataContext";
interface MultiLanguageSelectorProps {
    selectedLanguages: string[];
    setSelectedLanguages: React.Dispatch<React.SetStateAction<string[]>>;
}
// FIXME: Pass as parameter the data and change languages based on DataContext

export const MultiLanguageSelector: FC<MultiLanguageSelectorProps> = ({ selectedLanguages, setSelectedLanguages }) => {
    const dataContext = useContext(DataContext);
    let { languages } = dataContext!;
    languages = languages.map(lang => (lang === "EN" ? "GB" : lang));
    return (
        <MultiSelector values={selectedLanguages} onValuesChange={setSelectedLanguages}>
            <MultiSelectorTrigger>
                <MultiSelectorInput placeholder="Select your languages" />
            </MultiSelectorTrigger>
            <MultiSelectorContent>
                <MultiSelectorList>
                    {languages?.map(language => (
                        <MultiSelectorItem className="flex flex-row gap-2 items-center justify-center" value={language}>
                            <div>{getFlagEmoji(language)}</div>
                            {language}
                        </MultiSelectorItem>
                    ))}
                </MultiSelectorList>
            </MultiSelectorContent>
        </MultiSelector>
    );
};
