import { Outlet, NavLink } from "react-router-dom";

import styles from "./Layout.module.css";
import strings from "../../localization/strings";
import { LoginButton } from "../../components/LoginButton";
import { useLogin, isLoggedIn, loginRequest, getRedirectUri, appServicesLogout, appServicesToken } from "../../authConfig";
import { ChatHistory } from "../../components/ChatHistory";
import { useContext, useState } from "react";
import { useMsal } from "@azure/msal-react";
import SideBarToggle from "@/components/SideBarToggle";
import { motion } from "framer-motion";
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from "@/components/ui/drawer";
import { AutoLogout } from "../../authConfig";
type NavLinkClassFuncProps = {
    isActive: boolean;
};
const Layout = () => {
    const client = useLogin ? useMsal().instance : undefined;
    const [chatHistoryOpen, setChatHistoryOpen] = useState<boolean>(true);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const Loggedin = (activeAccount || appServicesToken) != null;

    let tabs = [
        { id: "", label: "Chat" },
        { id: "product", label: "Product" }
    ];
    const [activeTab, setActiveTab] = useState(tabs[0].id);
    return (
        <div className={`flex flex-row bg-background h-full `}>
            <ChatHistory isOpen={chatHistoryOpen} disabled={!isLoggedIn(client)} activeTab={activeTab} />
            <div className="flex w-full flex-col ">
                {/* <SideBarToggle isOpen={chatHistoryOpen} setIsOpen={setChatHistoryOpen} /> */}
                {
                    <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden flex-shrink-1">
                        <Outlet />
                    </div>
                }
            </div>
        </div>
    );
};

export default Layout;
