import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { Stepper } from "../Stepper/Stepper";
import { Button } from "./button";
import { useFileHandler } from "@/lib/hooks";
import { DataContext } from "@/context/dataContext";
import { useContext } from "react";

export function Toaster() {
    const { toasts } = useToast();
    const { handleCancelProcess } = useFileHandler();
    const dataContext = useContext(DataContext);
    if (!dataContext) return null;
    const { fileData, choose } = dataContext;
    return (
        <ToastProvider>
            {toasts.map(function ({ id, title, description, action, step, batch_job_id, file_name, ...props }) {
                if (step) {
                    return (
                        <Toast className="justify-center w-full max-h-[120px] h-full" key={id} {...props}>
                            <div className="flex flex-col gap-2 justify-center h-full items-center w-full">
                                {title && <ToastTitle>{title}</ToastTitle>}
                                <Stepper step={step}></Stepper>
                            </div>
                            {/* <Button
                                onClick={() => {
                                    if (file_name !== undefined) {
                                        handleCancelProcess(file_name, batch_job_id);
                                    }
                                    console.log("Canceling process");
                                }}
                                size={"sm"}
                                className="absolute bottom-2 right-2"
                                variant={"ghost"}
                            >
                                Cancel
                            </Button> */}
                        </Toast>
                    );
                }
                return (
                    <Toast key={id} {...props}>
                        <div className="grid gap-1">
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && <ToastDescription>{description}</ToastDescription>}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                );
            })}
            <ToastViewport />
        </ToastProvider>
    );
}
