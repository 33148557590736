import { ReactNode } from "react";

type FormWrapperProps = {
    title: string;
    description: string;
    children: ReactNode;
    error: string;
    footer: string;
};

export const FormWrapper = ({ title, description, footer, error, children }: FormWrapperProps) => {
    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
                <h2 className="text-lg font-semibold text-primary-text ">{title}</h2>
                <p className="text-sm text-muted-foreground">{description}</p>
            </div>

            {children}
            <div>
                <div className="flex items-center justify-center">
                    <span className={`font-light text-sm ${error ? "text-red-400" : "text-muted-foreground"} `}>{error ? error : footer}</span>
                </div>
            </div>
        </div>
    );
};
