import DOMPurify from "dompurify";
interface DescriptionProps {
    result: string | undefined;
}
const ParseDescription = ({ result }: DescriptionProps): JSX.Element => {
    if (result === undefined) {
        throw new Error("No result specified from stream");
    }
    result = result.replace("\\*g", "");
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result) }}></div>;
};
export default ParseDescription;
