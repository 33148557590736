import { ProgressStatus } from "@/api/models";
import { DataContext } from "@/context/dataContext";
import { type ClassValue, clsx } from "clsx";
import { useContext } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function getStatusByFilename(filename: string): "partial" | "processing" | "success" | "failed" | "not initiated" | undefined {
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("DataContext must be used within a DataProvider");
    }
    const { progressStatus } = dataContext;
    const file = progressStatus.find(file => file.name === filename);
    return file ? file.status : undefined;
}
export const getFlagEmoji = (countryCode: string) => String.fromCodePoint(...[...countryCode.toUpperCase()].map(x => 0x1f1a5 + x.charCodeAt(0)));

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const handleFileColor = (message: string) => {
    let trimmedMessage = message.replace("_processed", "");
    if (getStatusByFilename(trimmedMessage) === "partial") {
        return "border-yellow-400";
    } else if (getStatusByFilename(trimmedMessage) === "failed") {
        return "border-red-500";
    } else if (getStatusByFilename(trimmedMessage) === "success") {
        return "border-green-600";
    } else {
        return "border-[#b4b4b4]";
    }
};
export function isOverflown(container: HTMLDivElement | null) {
    if (!container) return false;
    return container.scrollHeight > container.clientHeight;
}
export const bottomOfContainer = (target: HTMLDivElement | null): boolean => {
    if (!target) return false;
    return Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) === 0;
};
export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function handleTime(hours: number | undefined) {
    if (hours === undefined) {
        return "0h:0m:0s";
    }
    const totalMinutes = hours * 60;
    const totalSeconds = totalMinutes * 60;

    const hoursPart = Math.floor(totalSeconds / 3600);
    const minutesPart = Math.floor((totalSeconds % 3600) / 60);
    const secondsPart = Math.floor(totalSeconds % 60);

    const time_string = `${hoursPart}h:${minutesPart}m:${secondsPart}s`;
    return time_string;
}
export function handleErrorMessage(error: any) {
    let errorMessage = "Error canceling the processing of a file. If you haven't refreshed the page in the past 24 hours, please refresh it.";

    if (error.response) {
        // Handle errors with response (likely an HTTP error)
        switch (error.response.status) {
            case 400:
                errorMessage = "Bad Request: The cancelation of the file has already been initated ";
                break;
            case 401:
                errorMessage = "Unauthorized: Your session might have expired. Please log in again.";
                break;

            case 404:
                errorMessage = "File Not Found: The file you're trying to cancel was not found.";
                break;
            case 500:
                errorMessage = "Server Error: Something went wrong on the server. Please try again later and contact support";
                break;
            default:
                errorMessage = `Unexpected Error: ${error.response.statusText} (${error.response.status}). Please try again.`;
                break;
        }
    }
    return errorMessage;
}
