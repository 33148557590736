import { CostInfo } from "@/api";
import { getFlagEmoji, handleTime } from "@/lib/utils";
import { BadgeEuro, Banknote, Box, FolderClock, Globe, Tag } from "lucide-react";
import { FC } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
interface FileStatsNavProps {
    costInfo: CostInfo | undefined;
}

export const FileStatsNav = ({ costInfo }: FileStatsNavProps): JSX.Element => {
    return (
        <nav className="grid gap-1 px-2">
            <div className="inline-flex items-center whitespace-nowrap text-base font-medium text-primary-text ring-offset-secondary transition-colors focus-visible:ring-2 focus-visible:ring-primary-text focus-visible:ring-offset-2 hover:bg-main h-9 rounded-md px-3 justify-start">
                <Box size={24} className="mr-2 h-5 w-5"></Box>
                Products
                <span className="text-sm font-medium text-primary-text ml-auto">{costInfo !== undefined && costInfo?.number_of_rows}</span>
            </div>
            <Accordion
                type="single"
                collapsible
                defaultValue="item-1"
                className="inline-flex items-center h-full whitespace-nowrap text-sm font-medium text-primary-text ring-offset-secondary transition-colors focus-visible:ring-2 focus-visible:ring-primary-text focus-visible:ring-offset-2 hover:bg-main h-9 rounded-md px-3 justify-start"
            >
                <AccordionItem value="item-1">
                    <AccordionTrigger className="text-base">
                        <Banknote className="mr-2 h-5 w-5"></Banknote>
                        Price Estimation
                    </AccordionTrigger>
                    <AccordionContent className="grid gap-1">
                        <div className="m-1 w-40 pl-4 inline-flex items-center whitespace-nowrap text-sm font-medium text-primary-text ring-offset-secondary transition-colors focus-visible:ring-2 focus-visible:ring-primary-text focus-visible:ring-offset-2 hover:bg-main h-8 rounded-md justify-start">
                            <BadgeEuro className="mr-2 h-4 w-4"></BadgeEuro>
                            Total
                            <span className="text-sm font-medium text-primary-text ml-auto">{costInfo !== undefined && costInfo?.total_cost.toFixed(2)}</span>
                        </div>
                        <div className="m-1 w-40 pl-4 inline-flex items-center whitespace-nowrap text-sm font-medium text-primary-text ring-offset-secondary transition-colors focus-visible:ring-2 focus-visible:ring-primary-text focus-visible:ring-offset-2 hover:bg-main h-8 rounded-md justify-start">
                            <Tag className="mr-2 h-4 w-4"></Tag>
                            Per Product
                            <span className="text-sm font-medium text-primary-text ml-auto">
                                {costInfo !== undefined && costInfo?.total_cost_one_item.toFixed(2)}
                            </span>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <div className="inline-flex items-center whitespace-nowrap text-base font-medium text-primary-text ring-offset-secondary transition-colors focus-visible:ring-2 focus-visible:ring-primary-text focus-visible:ring-offset-2 hover:bg-main h-9 rounded-md px-3 justify-start">
                <FolderClock className="mr-2 h-5 w-5"></FolderClock>
                Time
                <span className="text-sm font-medium text-primary-text ml-auto">{costInfo !== undefined && handleTime(costInfo.total_time)}</span>
            </div>
            <p className="text-muted-foreground text-[10px] -mt-2 px-3">* This is only the estimation of the processing not the uploading</p>
        </nav>
    );
};
