import { ChangeEvent, FC } from "react";
import { useToast } from "../ui/use-toast";
import { Label } from "../ui/label";
import { FormWrapper } from "./FormWrapper";

interface UploadStepProps {
    isUploading: boolean;
    isProcessing: boolean;
    handleUploadFile: () => (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
    error: string;
    uploaded: boolean;
}
export const UploadStep = ({ isUploading, isProcessing, handleUploadFile, error, uploaded }: UploadStepProps) => {
    const { toast } = useToast();
    return (
        <FormWrapper title="Upload Files" description="Upload files to create Product Descriptions." error={error} footer="Please only upload csv files">
            <div className="flex flex-col items-center justify-center gap-4">
                <div className="bg-primary font-semibold  text-white rounded-md px-6 py-3">
                    <Label className="">
                        {isUploading ? (
                            <div className="flex flex-row items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                <p className="cursor-not-allowed">Uploading</p>
                            </div>
                        ) : (
                            <p>{uploaded ? "Uploaded" : "Upload"}</p>
                        )}

                        <input
                            accept=".csv"
                            className={` ${isUploading ? "cursor-not-allowed" : "cursor-pointer"} opacity-0 w-1/2 h-1/2 top-14 right-32 bottom-0 absolute `}
                            type="file"
                            onChange={handleUploadFile()}
                            disabled={isUploading || isProcessing}
                            onError={() => {
                                toast({
                                    variant: "destructive",
                                    title: "Uh oh! Something went wrong.",
                                    description: "Couldn't upload file make sure its a csv file"
                                });
                            }}
                        />
                    </Label>
                </div>
            </div>
        </FormWrapper>
    );
};
