import { useState, ChangeEvent, useContext, useEffect, useRef } from "react";
import { getToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import {
    descriptionUploadApi,
    deleteUploadedFileApi,
    listUploadedFilesApi,
    downloadFileApi,
    descriptionProcessApi,
    descriptionProgressApi,
    CostInfo,
    languagesApi,
    cancelProcessApi
} from "../api";
import { useToast, ToasterToast } from "@/components/ui/use-toast";
import { DataContext } from "../context/dataContext";
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { BrowserAuthErrorCodes } from "@azure/msal-browser";
import { setLanguage } from "@fluentui/react";
import { Batch } from "../api/models";
import { buildTransform } from "framer-motion";
type ToastObject = {
    id: string;
    dismiss: () => void;
    update: (props: ToasterToast) => void;
    currentStatus: string;
    file_name: string;
};

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [value, setValue] = useState<T>(() => {
        return JSON.parse(localStorage.getItem(key) || JSON.stringify(initialValue));
    });
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);
    return [value, setValue];
}

export function useFetchProgress() {
    const client = useMsal().instance;
    const { toast } = useToast();
    const dataContext = useContext(DataContext);
    const controller = useRef(new AbortController());
    if (!dataContext) {
        throw new Error("DataContext must be used within a DataProvider");
    }
    const { setDescriptionInfo, setProgressStatus, setChoose, setFileData, setProgress, setCostInfo, setIsProcessing } = dataContext;
    let toastMap = new Map<string, ToastObject>();
    class FatalError extends Error {}
    class RetriableError extends Error {}
    function progressBody(event: EventSourceMessage, fileName: string) {
        const parsedData = JSON.parse(event.data);
        console.log(parsedData);
        const batches: Batch[] = parsedData.batches;
        if (parsedData.status === "Started") {
            setProgressStatus(prevStatus =>
                prevStatus.map(item =>
                    item.name === "Product_description_files/" + fileName
                        ? { ...item, status: "processing", number_of_processed: parsedData.total_processed }
                        : item
                )
            );
            setProgress(parsedData.total_progress + 0.01);
            batches.forEach(batch => {
                let file_batch_check = batch.id.includes("file");
                let normalizedId = batch.id.trim().toLowerCase();
                if (!file_batch_check) {
                    normalizedId = batch.input_file_id.trim().toLowerCase();
                }

                let existingToast = toastMap.get(normalizedId);

                if (existingToast) {
                    if (batch.status !== existingToast.currentStatus) {
                        existingToast.currentStatus = batch.status;
                        // FIXME: Not sure if setting dismissable to true will actually dismiss it
                        if (batch.status === "in_progress" || batch.status === "validating" || batch.status === "uploading") {
                            existingToast.update({
                                id: existingToast.id,
                                step: batch.status,
                                dismissAble: true,
                                file_name: fileName
                            });
                            console.log("Updating toast to status:", batch.status);
                        } else if (batch.status === "completed" || batch.status === "cancelled") {
                            setInterval(() => {
                                if (existingToast) {
                                    existingToast.dismiss();
                                }
                            }, 10000);
                        }
                    }
                } else {
                    if (batch.status === "in_progress" || batch.status === "validating" || batch.status === "pending") {
                        const { id, dismiss, update } = toast({
                            step: batch.status === "pending" ? "uploading" : batch.status,
                            variant: "batches",
                            description: `The processing of batch is in progress`,
                            dismissAble: false,
                            title: `Processing Batch: ${batch.name.slice(-2)}`,
                            batch_job_id: batch.id,
                            file_name: fileName
                        });

                        toastMap.set(normalizedId, { id: id, dismiss, update, currentStatus: batch.status, file_name: fileName });
                    }
                }
            });
        } else if (parsedData.status === "Failed") {
            // TODO: Set proccessed row, number of rows, total_time you can just use costInfo as type
            let selectedLanguages = batches.map(batch => batch.name.slice(-2));
            setProgressStatus(prevStatus =>
                prevStatus.map(item =>
                    item.name === "Product_description_files/" + fileName
                        ? { ...item, status: "failed", number_of_processed: parsedData.total_processed, batches: batches, languages: selectedLanguages }
                        : item
                )
            );
            setFileData(new FormData());
            setChoose(null);
            toastMap.forEach(toast => {
                toast.dismiss();
            });
        } else if (parsedData.status === "Stopped") {
            // TODO Checks batches and add as languages to localStorage so it can be retried
            let selectedLanguages = batches.map(batch => batch.name.slice(-2));
            setProgressStatus(prevStatus =>
                prevStatus.map(item =>
                    item.name === "Product_description_files/" + fileName
                        ? { ...item, status: "partial", number_of_processed: parsedData.total_processed, batches: batches, languages: selectedLanguages }
                        : item
                )
            );
            toastMap.forEach(toast => {
                toast.dismiss();
            });
            setFileData(new FormData());
            setChoose(null);
        } else if (parsedData.status === "Uploaded") {
            setProgressStatus(prevStatus =>
                prevStatus.map(item =>
                    item.name === "Product_description_files/" + fileName
                        ? { ...item, status: "success", number_of_processed: parsedData.total_processed, batches: batches }
                        : item
                )
            );
            setDescriptionInfo({ original: undefined, result: undefined });
            setCostInfo(undefined);
            setFileData(new FormData());
            setChoose(null);
            toastMap.forEach(toast => {
                toast.dismiss();
            });
            throw new FatalError();
        }
    }

    async function handleProgress(fileName: string, endpoint: RequestInfo) {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        setIsProcessing(true);
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${idToken}`);
        headers.append("Accept", `text/event-stream`);
        async function startEventSource() {
            try {
                await fetchEventSource(endpoint, {
                    signal: controller.current.signal,
                    headers: Object.fromEntries(headers),
                    async onopen(response) {
                        if (response.ok && response.status === 200) {
                            console.log("Connection opened!");
                        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                            throw new FatalError();
                        } else {
                            throw new RetriableError();
                        }
                    },
                    onmessage(msg) {
                        progressBody(msg, fileName);
                        if (msg.event === "FatalError") {
                            throw new FatalError(msg.data);
                        }
                    },
                    onclose() {
                        console.log("Connection closed by the server");
                        controller.current.abort();
                        controller.current = new AbortController();
                        console.log("Signal Aborted!");
                        throw new FatalError("Connection closed by the server");

                        // Optionally add a delay before reconnecting
                    },
                    onerror() {
                        throw new FatalError("Connection closed");
                    }
                });
            } catch (error) {
                console.error("Failed to connect. Retrying...", error);
                // Optionally add a delay before reconnecting
            } finally {
                setIsProcessing(false);
            }
        }

        startEventSource(); // Start the event source connection
    }

    return { handleProgress, progressBody };
}
export function useFileHandler() {
    const [error, setError] = useState<string>("");
    const client = useMsal().instance;
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("DataContext must be used within a DataProvider");
    }
    const { handleProgress } = useFetchProgress();
    const {
        setCostInfo,
        setDescriptionInfo,
        setIsUploading,
        setFileData,
        setProgressStatus,
        choose,
        setIsProcessing,
        setDeletionStatus,
        setChoose,
        fileData,
        setLastChunk,
        progressStatus,
        setLanguages
    } = dataContext;

    const { toast } = useToast();
    const handleLanguageApi = async (fileName: string): Promise<string[]> => {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        const response = await languagesApi(fileName, idToken);
        setProgressStatus(prevStatus =>
            prevStatus.map(item => (item.name === "Product_description_files/" + fileName ? { ...item, languages: response } : item))
        );
        return response;
    };
    const handleGetDescription = async (
        fileName: string,
        statistics: string,
        probe: string,
        language: string = "NL",
        languages: Array<string> = [],
        fetch_languages: string = "False"
    ) => {
        let answer = "";
        setIsUploading(true);
        if (probe !== "False" && statistics !== "False") {
            setLastChunk(false);
            setDescriptionInfo({ original: undefined, result: undefined });
            setCostInfo(undefined);
        } else if (statistics === "False") {
            setLastChunk(false);
            setDescriptionInfo({ original: undefined, result: undefined });
        }
        // Using a slight delay to not overwelhm the UI
        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    setDescriptionInfo(prevStatus => ({
                        ...prevStatus,
                        result: answer
                    }));
                    resolve(null);
                }, 33);
            });
        };
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            for await (const response of descriptionProcessApi(fileName, idToken, statistics, probe, languages, fetch_languages, language)) {
                if (probe === "True" || statistics === "True") {
                    if (response.total_cost) {
                        setCostInfo(response);
                        // FIXME: No need to add cost in Product.tsx but can just added it here..
                        setProgressStatus(prevStatus =>
                            prevStatus.map(item =>
                                item.name === "Product_description_files/" + fileName
                                    ? {
                                          ...item,
                                          total_cost: response.total_cost,
                                          total_time: response.total_time,
                                          number_of_rows: response.number_of_rows,
                                          languages: []
                                      }
                                    : item
                            )
                        );
                    } else if (response.original || response.original === null) {
                        if (response.original === null) {
                            setDescriptionInfo(prevStatus => ({
                                ...prevStatus,
                                original: "No original description was provided in the file"
                            }));
                        } else {
                            setDescriptionInfo(prevStatus => ({
                                ...prevStatus,
                                original: response.original
                            }));
                        }
                    } else if (response.content) {
                        await updateState(response.content);
                    } else if (response.result) {
                        setDescriptionInfo(prevStatus => ({
                            ...prevStatus,
                            result: response.result
                        }));
                        setLastChunk(true);
                    }
                } else if (response.status) {
                    return response;
                }
                if (fetch_languages === "True") {
                    return response;
                }
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: "Error getting the description of a file from the sidebar. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
            console.log(error);
            setIsUploading(false);
        } finally {
            setIsUploading(false);
        }
    };

    const handleRemoveFile = async (fileName: string, server: string, local: string, database: string) => {
        setDeletionStatus(fileName);
        console.log("Removing file", choose, fileName);
        if (fileData.has("file")) {
            let file = fileData.get("file") as File;
            let name: string = "Product_description_files/" + file.name;
            if (name === fileName) {
                setCostInfo(undefined);
                setDescriptionInfo({ original: undefined, result: undefined });
                setFileData(new FormData());
            }
        } else if (choose !== null && choose === fileName) {
            setCostInfo(undefined);
            setDescriptionInfo({ original: undefined, result: undefined });
            setChoose(null);
        }

        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }

            await deleteUploadedFileApi(fileName, idToken, server, local, database);
            // delete the normal counterpart and then rebuild it by calling description endpoint
            let fileNameToDelete = fileName.replace(".csv", "");
            fileNameToDelete = fileNameToDelete + "_processed";
            fileNameToDelete = fileNameToDelete + ".csv";
            await deleteUploadedFileApi(fileNameToDelete, idToken, "True", "True", "False");
            setProgressStatus(prevState => {
                return prevState.filter(file => file.name !== fileName);
            });
            setDeletionStatus("");
            toast({
                variant: "success",
                title: "File Removed Successfully",
                description: "Your file has been removed successfully"
            });
        } catch (error) {
            setDeletionStatus("");
            console.error(error);
        }
    };
    const handleDownloadFile = async (fileName: string) => {
        setDeletionStatus(fileName);
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        try {
            let item = progressStatus.find(item => item.name === fileName);
            if (item?.status === "success" || item?.status === "partial") {
                fileName = fileName.replace(".csv", "_processed.csv");
            }
            const file = await downloadFileApi(fileName, idToken);
            let fileURL = URL.createObjectURL(file);
            // Create URL for blob
            const a = document.createElement("a");
            if (item?.status === "partial") {
                fileName = fileName.replace("_processed.csv", "_partially_processed.csv");
            }
            fileName = fileName.replace("Product_description_files/", "");

            a.href = fileURL;
            a.download = fileName;
            // Append the child
            document.body.appendChild(a);
            a.click();
            // Free up memory
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
            setDeletionStatus("");
            toast({
                variant: "success",
                title: "Downloaded The File Successfully",
                description: "You have downloaded the file successfully now you can use it."
            });
        } catch (error) {
            setDeletionStatus("");
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: "Error downloading a file. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
            console.log(error);
        }
    };
    const handleRetry = async (fileName: string, cost: number, time: number, rows: number, languages: string[]) => {
        setIsProcessing(true);
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            if (fileName) {
                setProgressStatus(prevState => [
                    ...prevState,
                    {
                        name: "Product_description_files/" + fileName,
                        status: "not initiated",
                        total_cost: cost,
                        total_time: time,
                        number_of_rows: rows,
                        number_of_processed: undefined,
                        languages: languages,
                        batches: []
                    }
                ]);
                await handleProgress(
                    fileName,
                    "/description?" +
                        new URLSearchParams({
                            file_name: fileName,
                            statistics: "False",
                            probe: "False",
                            languages: languages.join(","),
                            fetch_languages: "False"
                        })
                );
            }
        } catch (error) {
            console.error("Error processing data:", error);
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description:
                    "You have to upload a file first or choose one from the sidebar. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
        } finally {
            setIsProcessing(false);
            setChoose(null);
        }
    };
    const handleGetStatistics = async (fileName: string) => {
        try {
            const idToken = await getToken(client);
            console.log("idToken", idToken);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            for await (const response of descriptionProcessApi(fileName, idToken, "True", "False", [""], "False", "NL")) {
                if (response.total_cost) {
                    const stats: CostInfo = {
                        number_of_rows: response.number_of_rows,
                        total_cost: response.total_cost,
                        total_time: response.total_time,
                        total_cost_one_item: response.total_cost_one_item
                    };

                    return stats;
                }
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: "Error getting the statistics of a file from the sidebar. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
            console.log(error);
        }
    };
    const handleCancelProcess = async (fileName: string, batch_job_id: string = "") => {
        setDeletionStatus(fileName);
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            fileName = fileName.replace("Product_description_files/", "");
            await cancelProcessApi(fileName, idToken, batch_job_id);
            if (batch_job_id === "") {
                setProgressStatus(prevStatus =>
                    prevStatus.map(item => (item.name === "Product_description_files/" + fileName ? { ...item, status: "partial" } : item))
                );
            }
            setDeletionStatus("");
        } catch (error: any) {
            setDeletionStatus("");
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: "Error cancling process. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
            console.error("Error cancling process: ", error);
        }
    };
    return {
        handleRemoveFile,
        handleDownloadFile,
        handleGetDescription,
        handleRetry,
        handleGetStatistics,
        handleLanguageApi,
        handleCancelProcess
    };
}
