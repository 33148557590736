import React, { FC, useState } from "react";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "./ui/chart";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from "recharts";
import { CostChartData, TokenChartData } from "../api/models";
interface CostChartProps {
    chartData: CostChartData[] | undefined;
    chartConfig: ChartConfig;
}
interface TokenChartProps {
    chartData: TokenChartData[];
    chartConfig: ChartConfig;
}

const CostChart: FC<CostChartProps> = ({ chartData, chartConfig }): JSX.Element => {
    return (
        <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
            <BarChart accessibilityLayer data={chartData}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="month" tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value: string) => value.slice(0, 3)} />
                <ChartTooltip content={<ChartTooltipContent className="euro" />} />
                <Bar dataKey="cost" fill="#0661fe" radius={4} />
            </BarChart>
        </ChartContainer>
    );
};
const TokenChart: FC<TokenChartProps> = ({ chartData, chartConfig }): JSX.Element => {
    return <div></div>;
};
export { TokenChart, CostChart };
