const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, SimpleAPIResponse, ColumnsMatchingData } from "./models";
import { useLogin, appServicesToken } from "../authConfig";
import { StringOrTemplateHeader } from "@tanstack/react-table";
import { backIn } from "framer-motion";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            return { Authorization: `Bearer ${idToken}` };
        }
    }

    return {};
}

export async function configApi(): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET"
    });

    return (await response.json()) as Config;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}
export async function downloadFileApi(fileName: string, idToken: string): Promise<Blob> {
    const response = await fetch(`/blob?` + new URLSearchParams({ path: fileName }), {
        method: "GET",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" }
    });
    if (!response.ok) {
        throw new Error(`Downloading file failed: ${response.statusText}`);
    }
    const file = await response.blob();
    return file;
}
export async function tokenGraphApi(fileName: string, idToken: string) {
    const response = await fetch("/file_chart?" + new URLSearchParams({ file_name: fileName }), {
        method: "GET",
        headers: getHeaders(idToken)
    });
    if (!response.ok) {
        throw new Error(`Description Upload failed: ${response.statusText}`);
    }

    const responseText = await response.text();
    const responseJSON = JSON.parse(responseText);

    return responseJSON;
}
export async function userStatsApi(idToken: string) {
    const response = await fetch("/user_stats", {
        method: "GET",
        headers: getHeaders(idToken)
    });
    if (!response.ok) {
        throw new Error(`Description Upload failed: ${response.statusText}`);
    }

    const responseText = await response.text();
    const responseJSON = JSON.parse(responseText);
    return responseJSON;
}
type ProgressUpdate = { progress: number };
export async function* descriptionProgressApi(fileName: string, idToken: string) {
    let response: Response;
    fileName = fileName.replace("Product_description_files/", "");
    try {
        response = await fetch(`/description/progress?` + new URLSearchParams({ file_name: fileName }), {
            method: "GET",
            headers: getHeaders(idToken)
        });
    } catch (networkError) {
        throw new Error(`Network error occurred: ${networkError}`);
    }
    if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Get progress of description failed: ${errorDetails}`);
    }
    if (!response.body) {
        throw new Error("Response body is null");
    }
    // Check if its processed, if it is then just yield and return
    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let done = false;
    let isFirstChunk = true;
    while (!done) {
        try {
            const { value, done: readerDone } = await reader.read();
            console.log(value);
            done = readerDone;
            if (value) {
                const chunk = decoder.decode(value, { stream: true });
                let cleanedChunk = chunk.replace(/^data: /, "");
                cleanedChunk = cleanedChunk.replace("retry: 5000", "");
                if (isFirstChunk) {
                    console.log("calling first chunk");
                    isFirstChunk = false;
                    try {
                        console.log(cleanedChunk);
                        const responseJSON = JSON.parse(cleanedChunk);
                        // Check if progress is 1 in the firstChunk
                        if (responseJSON.status === "Uploaded") {
                            yield responseJSON;
                            return;
                        } else if (responseJSON.status === "Not_initiated") {
                            yield responseJSON;
                            return;
                        }
                    } catch (error) {
                        console.error("Failed to parse initial JSON response:", error);
                        throw new Error("Initial JSON response parsing failed");
                    }
                }
                try {
                    console.log(cleanedChunk);
                    const json = JSON.parse(cleanedChunk);
                    if (json.status === "Started") {
                        yield json;
                    } else if (json.status === "Failed") {
                        yield json;
                        throw new Error("Error occured in uploading the file");
                    } else if (json.status === "Finished") {
                        console.log("returning finished");
                        yield json;
                        return;
                    }
                } catch (error) {
                    console.error("Failed to parse JSON:", error);
                }
            }
        } catch (error) {
            console.error("Stream reading error:", error);
            done = true; // Exit the loop if an error occurs
        }
    }
}
export async function columnsMatchingApi(fileName: string, idToken: string, method: string, columns: ColumnsMatchingData | undefined) {
    let response: Response;
    if (method === "PUT" && columns) {
        response = await fetch(`/description?` + new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False" }), {
            method: "PUT",
            headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
            body: JSON.stringify({ matched: columns.matched })
        });
    } else {
        response = await fetch(`/description?` + new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False", fetch_columns: "True" }), {
            method: "GET",
            headers: getHeaders(idToken)
        });
    }

    if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Get progress of description failed: ${errorDetails}`);
    }
    const responseData = await response.text();
    const responseJSON = JSON.parse(responseData);
    return responseJSON;
}
export async function languagesApi(fileName: string, idToken: string) {
    const response = await fetch(`/description?` + new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False", fetch_languages: "True" }), {
        method: "GET",
        headers: getHeaders(idToken)
    });
    if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Get progress of description failed: ${errorDetails}`);
    }
    let responseData = await response.text();
    responseData = responseData.replace(/'/g, '"');
    const responseJSON = JSON.parse(responseData);
    return responseJSON;
}
export async function* descriptionProcessApi(
    fileName: string,
    idToken: string,
    statistics: string,
    probe: string,
    languages: Array<string>,
    fetch_languages: string,
    language: string
) {
    fileName = fileName.replace("Product_description_files/", "");
    let response: Response;
    try {
        response = await fetch(
            `/description?` +
                new URLSearchParams({
                    file_name: fileName,
                    statistics: statistics,
                    probe: probe,
                    languages: languages.join(","),
                    fetch_languages: fetch_languages,
                    language: language
                }),
            {
                method: "GET",
                headers: getHeaders(idToken)
            }
        );
    } catch (networkError) {
        throw new Error(`Network error occurred: ${networkError}`);
    }
    if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Get progress of description failed: ${errorDetails}`);
    }
    if (!response.body) {
        throw new Error("Response body is null");
    }
    if (fetch_languages === "True") {
        let languages = await response.text();
        languages = JSON.parse(languages);
        return languages;
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;

    while (!done) {
        try {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            if (value && (probe === "True" || statistics === "True")) {
                const chunk = decoder.decode(value, { stream: true });
                const parts = chunk.split("\n");
                if (parts.length >= 2) {
                    let cleanedChunk1 = parts[0].replace(/^data: /, "");
                    console.log(parts[0]);
                    let cleanedChunk2 = parts[1].replace("event: ", "");
                    const json1 = JSON.parse(cleanedChunk1);
                    const json2 = JSON.parse(cleanedChunk2);
                    console.log(json1);
                    yield json1;
                }
            }
        } catch (error) {
            console.error("Error streaming ", error);
            done = true;
        }
    }
}
export async function processApi(fileName: string, idToken: string, languages: string) {
    const response = await fetch(`/description?` + new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False", languages: languages }), {
        method: "GET",
        headers: getHeaders(idToken)
    });
    if (!response.ok) {
        throw new Error(`Processing failed: ${response.statusText}`);
    }

    const responseText = await response.text();
    const responseJSON = JSON.parse(responseText);
    return responseJSON;
}
// if there is no action specified than it starts the processing of a file and responds with progress only once
export async function descriptionUploadApi(request: FormData, idToken: string) {
    const response = await fetch("/description", {
        method: "POST",
        headers: getHeaders(idToken),
        body: request
    });
    if (!response.ok) {
        throw new Error(`Description Upload failed: ${response.statusText}`);
    }
    // Fetch response as text
    const responseText = await response.text();
    const message = JSON.parse(responseText);
    // Log the response text for debugging
    // Split the response text by new lines or another appropriate delimiter

    return message;
}
export async function fileStatsApi(fileName: string, idToken: string) {
    const response = await fetch("/file_stats?" + new URLSearchParams({ file_name: fileName }), {
        method: "GET",
        headers: getHeaders(idToken)
    });
    if (!response.ok) {
        throw new Error(`File Stats failed: ${response.statusText}`);
    }

    const responseText = await response.text();
    const responseJSON = JSON.parse(responseText);
    console.log(responseJSON);
    return responseJSON;
}
export async function uploadFileApi(request: FormData, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/upload", {
        method: "POST",
        headers: getHeaders(idToken),
        body: request
    });

    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}
export async function cancelProcessApi(fileName: string, idToken: string, batch_job_id: string) {
    let queryParams;
    if (batch_job_id === "") {
        queryParams = { file_name: fileName };
    } else {
        queryParams = { file_name: fileName, batch_job_id: batch_job_id };
    }

    const response = await fetch(`/cancel_processing?` + new URLSearchParams(queryParams), {
        method: "GET",
        headers: getHeaders(idToken)
    });
    if (!response.ok) {
        throw new Error(`Canceling file failed: ${response.statusText}`);
    }

    return response.text();
}
export async function deleteUploadedFileApi(fileName: string, idToken: string, server: string, local: string, database: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/blob?" + new URLSearchParams({ path: fileName, server: server, local: local, database: database }), {
        method: "DELETE",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" }
    });

    if (!response.ok) {
        throw new Error(`Deleting file failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function listUploadedFilesApi(idToken: string): Promise<string[]> {
    const response = await fetch("/list_uploaded", {
        method: "GET",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Listing files failed: ${response.statusText}`);
    }
    const dataResponse: string[] = await response.json();
    return dataResponse;
}
