import { FC, useContext } from "react";
import { FormWrapper } from "./FormWrapper";
import { ColumnsMatchingData } from "@/api";
import { Selector } from "../Selectors/Selector";
import { DataContext } from "@/context/dataContext";

interface ColumnsMatchingProps {
    error: string;
    columns: ColumnsMatchingData | undefined;
    setColumns: React.Dispatch<React.SetStateAction<ColumnsMatchingData | undefined>> | undefined;
}
// TODO: Let them choose None rather than no choose == none
// TODO: Default values should be the matched columns
export const ColumnsMatching = ({ error, columns, setColumns }: ColumnsMatchingProps) => {
    return (
        <FormWrapper
            title="Match the columns for each category"
            description="Match all the columns for each category so we create description based on them choose the ones you want as you base language."
            error={error}
            footer=""
        >
            {columns ? (
                <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-3 gap-2">
                        <div className="flex flex-col gap-2">
                            <p>Brand</p>
                            <Selector columns={columns} setColumns={setColumns} languageSelector={false} label="brand"></Selector>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Category</p>
                            <Selector columns={columns} setColumns={setColumns} languageSelector={false} label="category"></Selector>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Description</p>
                            <Selector columns={columns} setColumns={setColumns} languageSelector={false} label="description"></Selector>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 ">
                        <div className="flex flex-col gap-2 ">
                            <p>Price</p>
                            <Selector columns={columns} setColumns={setColumns} languageSelector={false} label="price"></Selector>
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <p>Product Name</p>
                            <Selector columns={columns} setColumns={setColumns} languageSelector={false} label="product_name"></Selector>
                        </div>
                    </div>
                </div>
            ) : (
                <svg className="animate-spin self-center h-1/4 w-1/4 text-primary-text" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            )}
        </FormWrapper>
    );
};
