import React, { FC } from "react";
import { Step } from "./Step";
import { capitalizeFirstLetter } from "@/lib/utils";
interface StepperProps {
    step: string;
}
// TODO: Rewrite with data attributes
export const Stepper = ({ step }: StepperProps): JSX.Element => {
    return (
        <div className="flex flex-row gap-2 w-full h-full">
            <Step step={step} label={capitalizeFirstLetter(step.replace("_", " "))}></Step>
        </div>
    );
};
