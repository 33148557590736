import { descriptionProcessApi } from "@/api";
import { ColumnsMatchingData, CostInfo, DescriptionAPIResponse, DescriptionInfo, ProgressStatus } from "@/api/models";
import { getToken } from "@/authConfig";
import { useMsal } from "@azure/msal-react";
import React, { createContext, ReactNode, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { useLocalStorage } from "@/lib/hooks";
interface DataContextType {
    costInfo: CostInfo | undefined;
    setCostInfo: React.Dispatch<React.SetStateAction<CostInfo | undefined>>;
    descriptionInfo: DescriptionInfo | undefined;
    setDescriptionInfo: React.Dispatch<React.SetStateAction<DescriptionInfo>>;
    fileData: FormData;
    setFileData: React.Dispatch<React.SetStateAction<FormData>>;
    isProcessing: boolean;
    setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
    isUploading: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    choose: string | null;
    setChoose: React.Dispatch<React.SetStateAction<string | null>>;
    openDrawer: boolean;
    lastChunk: boolean;
    setLastChunk: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    progressStatus: ProgressStatus[];
    setProgressStatus: React.Dispatch<React.SetStateAction<ProgressStatus[]>>;
    progress: number | undefined;
    setProgress: React.Dispatch<React.SetStateAction<number | undefined>>;
    isDuplicate: boolean;
    setIsDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
    deletionStatus: string;
    setDeletionStatus: React.Dispatch<React.SetStateAction<string>>;
    columns: ColumnsMatchingData | undefined;
    setColumns: React.Dispatch<React.SetStateAction<ColumnsMatchingData | undefined>>;
    languages: Array<string>;
    setLanguages: React.Dispatch<React.SetStateAction<Array<string>>>;
    selectedLanguage: string | undefined;
    setSelectedLanguage: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedLanguages: string[];
    setSelectedLanguages: React.Dispatch<React.SetStateAction<string[]>>;
}
export const DataContext = createContext<DataContextType | undefined>(undefined);
interface DataProviderProps {
    children: ReactNode;
}

export const DataProvider = ({ children }: DataProviderProps) => {
    const [costInfo, setCostInfo] = useState<CostInfo | undefined>();
    const [fileData, setFileData] = useState<FormData>(new FormData());
    const [isProcessing, setIsProcessing] = useState(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [descriptionInfo, setDescriptionInfo] = useState<DescriptionInfo>({ original: undefined, result: undefined });
    const [choose, setChoose] = useState<string | null>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [progressStatus, setProgressStatus] = useLocalStorage<ProgressStatus[]>("progressStatus", []);
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [deletionStatus, setDeletionStatus] = useState<string>("");
    const [columns, setColumns] = useState<ColumnsMatchingData>();
    const [languages, setLanguages] = useState<Array<string>>([]);
    const [lastChunk, setLastChunk] = useState<boolean>(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    return (
        <DataContext.Provider
            value={{
                costInfo,
                setCostInfo,
                descriptionInfo,
                setDescriptionInfo,
                fileData,
                setFileData,
                isProcessing,
                setIsProcessing,
                isUploading,
                setIsUploading,
                choose,
                setChoose,
                progress,
                setProgress,
                deletionStatus,
                setDeletionStatus,
                openDrawer,
                setOpenDrawer,
                progressStatus,
                setProgressStatus,
                isDuplicate,
                setIsDuplicate,
                columns,
                setColumns,
                languages,
                setLanguages,
                lastChunk,
                setLastChunk,
                selectedLanguage,
                setSelectedLanguage,
                selectedLanguages,
                setSelectedLanguages
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
