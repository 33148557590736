import React, { FC, useContext, useEffect, useState } from "react";
import { listUploadedFilesApi, cancelProcessApi, descriptionProcessApi, ProgressStatus } from "../../api";

import { getToken, isLoggedIn } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import logo from "../../assets/Component 1.png";
import { DataContext } from "@/context/dataContext";
import { Progress } from "../ui/progress";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { ChevronRight, File } from "lucide-react";
import { LoginButton } from "../LoginButton";
import { DataTable } from "../DataTable";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../ui/sheet";
import { handleErrorMessage, handleFileColor } from "@/lib/utils";
import { RawFiles } from "./RawFiles";
import { ProcessedFiles } from "./ProcessedFiles";
import { useFetchProgress, useFileHandler } from "@/lib/hooks";
import { FileStatsNav } from "../FileStatsNav";
interface ChatHistoryProps {
    isOpen: boolean;
    disabled: boolean;
    activeTab: string;
}

const handleFileName = (fileName: string) => {
    if (fileName.includes("Product_description_files/")) {
        fileName = fileName.replace("Product_description_files/", "");
        fileName = fileName.replace(".csv", "");
        fileName = fileName.replace("_processed", "");
        if (fileName.length > 20) {
            return fileName.slice(0, 21);
        }
        return fileName;
    }
};
const handleSlice = (array: ProgressStatus[]) => {
    if (array.length > 5) {
        return array.slice(-5, array.length);
    }
    return array;
};
// Move to middle. I have to make the div with ol to be scrollable based on the data. Don't load everything. Have to be seperate of the account and also the new chat div
export const ChatHistory = ({ isOpen }: ChatHistoryProps): JSX.Element => {
    const client = useMsal().instance;
    // Handling data context for interaction between chatHistory and Product page
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("DataContext must be used within a DataProvider");
    }
    const [collapsed, setCollapsed] = useState<string | null>(null);
    const { toast } = useToast();
    const {
        costInfo,
        setChoose,
        setIsUploading,
        isUploading,
        setSelectedLanguage,
        setFileData,
        deletionStatus,
        setDeletionStatus,
        progressStatus,
        setProgressStatus,
        progress,
        isProcessing,
        setSelectedLanguages,
        setLanguages
    } = dataContext;
    const { instance } = useMsal();
    const { handleGetDescription, handleCancelProcess, handleGetStatistics, handleDownloadFile, handleRemoveFile, handleLanguageApi } = useFileHandler();
    const { handleProgress } = useFetchProgress();
    // handle Login and Logout
    // Should be called after the useEffect of checking the status of files
    // Check callBack function
    const listUploadedFiles = async (): Promise<string[]> => {
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }

            return await listUploadedFilesApi(idToken);
        } catch (error) {
            console.error("Error listing uploaded files:", error);
            return [""];
        }
    };

    // TODO: Delete currentFile variable

    const handleRemoveProgress = (fileName: string) => {
        if (fileName === deletionStatus) {
            return true;
        }
        return false;
    };
    // FIXME: Could not be working because progress is not being checked as its already in progressStatus
    useEffect(() => {
        // FIXME:  If there activeAccount
        const fetchFiles = async () => {
            setIsUploading(true);
            const files = await listUploadedFiles();
            const shouldProcessFiles = files.length !== 0;
            if (shouldProcessFiles) {
                let newFiles = files.filter(name => !name.includes("_processed") && name.includes("Product_description_files/"));
                newFiles = newFiles.filter(name => !progressStatus.some(file => name === file.name && file.status !== "processing"));

                for (let name of newFiles) {
                    let fileName = name.replace("Product_description_files/", "");

                    if (progressStatus.every(file => file.name !== name) || progressStatus.length === 0) {
                        const stats = await handleGetStatistics(fileName);

                        if (stats) {
                            setProgressStatus(prevState => [
                                ...prevState,
                                {
                                    name: name,
                                    status: "not initiated",
                                    total_cost: stats.total_cost,
                                    total_time: stats.total_time,
                                    number_of_rows: stats.number_of_rows,
                                    number_of_processed: undefined,
                                    languages: [],
                                    batches: []
                                }
                            ]);
                        }
                    }
                    // not awaiting atm so it does not wait for a processing of a file and then checks the rest of the files
                    handleProgress(fileName, "/description/progress?" + new URLSearchParams({ file_name: fileName }));
                }
            }
            setIsUploading(false);
        };
        if (isLoggedIn(instance)) {
            fetchFiles();
        }
    }, [instance]);
    // TODO: Test uploading a duplicate file

    // Downloads the file by creating a fileURL from the blob

    const handlePopover = (filename: string) => {
        if (collapsed === filename) return true;
        return false;
    };
    // Handle color of the files that are mapped used for also showing the error for a file
    return (
        <>
            {isOpen ? (
                <div className="h-full w-[12%] bg-background transition-all duration-300 shrink-0 border-r-2 border-border">
                    <div className="flex flex-col space-y-16">
                        <div className="h-[48%] flex flex-col space-y-8">
                            <div className="flex justify-between items-center h-[50px] p-3 mt-4">
                                <img src={logo} alt="UTF" width={30} height={30} />
                                <LoginButton />
                            </div>
                            <div className="group flex-col flex gap-4 py-2 w-full">
                                <FileStatsNav costInfo={costInfo}></FileStatsNav>
                            </div>
                        </div>
                        <div className="h-[80%] w-full">
                            <div className="p-2 flex items-center flex-col gap-4">
                                <div className="inline-flex w-full group items-center hover:text-secondary-text whitespace-nowrap text-base font-medium text-primary-text hover:bg-primary ring-offset-secondary transition-colors focus-visible:ring-2 focus-visible:ring-primary-text focus-visible:ring-offset-2 hover:bg-main h-9 rounded-md px-3 justify-start">
                                    <Sheet>
                                        <SheetTrigger asChild>
                                            <div className="w-full items-center inline-flex whitespace-nowrap cursor-pointer">
                                                <File className="mr-2 h-5 w-5 "></File>
                                                Files
                                                <ChevronRight className="h-4 w-4 shrink-0 transition-transform duration-200 ml-auto  cursor-pointer" />
                                            </div>
                                        </SheetTrigger>
                                        <SheetContent side={"left"} className="w-[1500px] sm:max-w-[1000px]">
                                            <div className="mx-auto max-w-2xl">
                                                <SheetHeader>
                                                    <SheetTitle>File Table</SheetTitle>
                                                </SheetHeader>
                                                <DataTable></DataTable>
                                            </div>
                                        </SheetContent>
                                    </Sheet>
                                </div>

                                {isLoggedIn(instance) &&
                                    handleSlice(progressStatus)?.map((file, index) => (
                                        <>
                                            {(handleFileColor(file.name) === "border-green-600" ||
                                                handleFileColor(file.name) === "border-yellow-400" ||
                                                handleFileColor(file.name) === "border-red-500") && (
                                                <ProcessedFiles
                                                    file={file}
                                                    handleDownloadFile={handleDownloadFile}
                                                    handleFileName={handleFileName}
                                                    handlePopover={handlePopover}
                                                    handleRemoveFile={handleRemoveFile}
                                                    handleRemoveProgress={handleRemoveProgress}
                                                    handleGetStatistics={handleGetStatistics}
                                                    collapsed={collapsed}
                                                    setCollapsed={setCollapsed}
                                                ></ProcessedFiles>
                                            )}
                                            {handleFileColor(file.name) === "border-[#b4b4b4]" && (
                                                <>
                                                    <div
                                                        className={`group w-full border-2 relative rounded-lg hover:bg-[#e8e8e8] cursor-pointer p-2 flex flex-col gap-2 items-center justify-center ${handleFileColor(
                                                            file.name
                                                        )}`}
                                                        key={file.name}
                                                    >
                                                        <div className="flex flex-row items-center w-full">
                                                            <a
                                                                className="flex items-center justify-center max-w-[90%] flex-grow"
                                                                onClick={async () => {
                                                                    if (!handleRemoveProgress(file.name) && !isUploading && !isProcessing) {
                                                                        const fileName = file.name.replace("Product_description_files/", "");
                                                                        await handleGetDescription(fileName, "True", "True", "NL");
                                                                        setSelectedLanguages([]);
                                                                        setSelectedLanguage(undefined);
                                                                        const languages = await handleLanguageApi(fileName);
                                                                        setLanguages(languages);
                                                                        setChoose(file.name);
                                                                        setFileData(new FormData());
                                                                    } else {
                                                                        toast({
                                                                            variant: "info",
                                                                            title: "Warning!",
                                                                            description: "Please wait for the generation of the description of the product."
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <p className="truncate relative text-sm overflow-hidden">{handleFileName(file.name)}</p>
                                                            </a>
                                                            {file.status === "processing" && deletionStatus !== file.name ? (
                                                                <TooltipProvider>
                                                                    <Tooltip>
                                                                        <TooltipTrigger
                                                                            asChild
                                                                            onClick={() => {
                                                                                handleCancelProcess(file.name);
                                                                                toast({
                                                                                    variant: "info",
                                                                                    title: "Warning!",
                                                                                    description: "Canceling of a file has been initiated"
                                                                                });
                                                                            }}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                className="feather feather-x"
                                                                            >
                                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                            </svg>
                                                                        </TooltipTrigger>
                                                                        <TooltipContent>
                                                                            <p>Cancel</p>
                                                                        </TooltipContent>
                                                                    </Tooltip>
                                                                </TooltipProvider>
                                                            ) : (
                                                                <Popover
                                                                    open={handlePopover(file.name)}
                                                                    onOpenChange={() => setCollapsed(collapsed === file.name ? null : file.name)}
                                                                    modal={true}
                                                                >
                                                                    <PopoverTrigger disabled={handleRemoveProgress(file.name)}>
                                                                        <TooltipProvider>
                                                                            <Tooltip>
                                                                                <TooltipTrigger asChild disabled={handleRemoveProgress(file.name)}>
                                                                                    {handleRemoveProgress(file.name) ? (
                                                                                        <svg
                                                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-text "
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                        >
                                                                                            <circle
                                                                                                className="opacity-25"
                                                                                                cx="12"
                                                                                                cy="12"
                                                                                                r="10"
                                                                                                stroke="currentColor"
                                                                                                stroke-width="4"
                                                                                            ></circle>
                                                                                            <path
                                                                                                className="opacity-75"
                                                                                                fill="currentColor"
                                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                            ></path>
                                                                                        </svg>
                                                                                    ) : (
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            stroke="currentColor"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            className="feather feather-more-vertical"
                                                                                        >
                                                                                            <circle cx="12" cy="12" r="1"></circle>
                                                                                            <circle cx="12" cy="5" r="1"></circle>
                                                                                            <circle cx="12" cy="19" r="1"></circle>
                                                                                        </svg>
                                                                                    )}
                                                                                </TooltipTrigger>
                                                                                <TooltipContent>
                                                                                    {handleRemoveProgress(file.name) ? (
                                                                                        <p className="text-primary-text">Removing</p>
                                                                                    ) : (
                                                                                        <p className="text-primary-text">Options</p>
                                                                                    )}
                                                                                </TooltipContent>
                                                                            </Tooltip>
                                                                        </TooltipProvider>
                                                                    </PopoverTrigger>

                                                                    <PopoverContent className="w-32 p-2">
                                                                        <div className="px-2 py-1.5 text-sm font-semibold">Actions</div>
                                                                        <div
                                                                            className="relative cursor-pointer hover:bg-[#f4f4f5] flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                                                                            onClick={() => {
                                                                                handleRemoveFile(file.name, "True", "True", "True");
                                                                                setCollapsed(null);
                                                                            }}
                                                                        >
                                                                            <p className="flex-grow text-red-600">Delete</p>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="red"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                className=""
                                                                            >
                                                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                                                            </svg>
                                                                        </div>
                                                                    </PopoverContent>
                                                                </Popover>
                                                            )}
                                                        </div>
                                                        {file.status === "processing" && progress !== undefined && (
                                                            <div className="flex flex-row gap-2 w-full justify-center">
                                                                <Progress value={Math.round(progress * 100)} className="w-full"></Progress>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ChatHistory;
