import React, { FC } from "react";
import { Separator } from "../ui/separator";
import { FileSearch, FileUp, LoaderCircle } from "lucide-react";

interface StepProps {
    step: string;
    label: string;
}

export const Step = ({ step, label }: StepProps): JSX.Element => {
    return (
        <div className="w-full flex items-center gap-2">
            <div className="flex items-center w-full justify-center flex-col gap-2">
                <div className="bg-white font-bold w-10 h-10 flex items-center rounded-full justify-center">
                    {step === "uploading" && <FileUp size={24} color={"black"} />}
                    {step === "validating" && <FileSearch size={24} color={"black"} />}
                    {step === "in_progress" && <LoaderCircle size={24} color={"black"} className="animate-spin" />}
                </div>
                <p className="text-sm text-secondary-text">{label}</p>
            </div>
        </div>
    );
};
