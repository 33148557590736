"use client";
import React, { useContext, useEffect, useState } from "react";
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { ArrowUpDown, ChevronDown, ChevronLeft, ChevronRight, CircleCheck, CircleHelp, CircleX, Clock12, FileInput, MoreHorizontal } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { DataContext } from "@/context/dataContext";
import { useFileHandler } from "../lib/hooks";
import { Dialog, DialogTrigger, DialogContent, DialogHeader } from "./ui/dialog";
import { TokenChart } from "./Charts";
import { getToken, isLoggedIn } from "@/authConfig";
import { useMsal } from "@azure/msal-react";
import { tokenGraphApi } from "@/api/api";
import { ProgressStatus } from "@/api/models";
import { DialogTitle } from "@fluentui/react-components";
// go over the file names save the file names to the name and then progresStatus[fileName] is the status you have maybe use map

const icons = {
    partial: <CircleHelp className="h-4 w-4" color="#FACC15"></CircleHelp>,
    processing: <Clock12 className="h-4 w-4" color="#71717a"></Clock12>,
    success: <CircleCheck className="h-4 w-4" color="#22C55E"></CircleCheck>,
    failed: <CircleX className="h-4 w-4" color="#F31234"></CircleX>
};

export const columns: ColumnDef<ProgressStatus>[] = [
    {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
            <div className="capitalize flex flex-row gap-1 items-center">
                {icons[row.getValue<"partial" | "processing" | "success" | "failed">("status")]}
                {row.getValue("status")}
            </div>
        )
    },
    {
        accessorKey: "name",
        header: ({ column }) => {
            return (
                <Button variant="link" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Name
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            let name: string = row.getValue("name");
            name = name.replace("Product_description_files/", "");
            return <div className="lowercase">{name}</div>;
        }
    },

    {
        accessorKey: "total_cost",
        header: ({ column }) => {
            return (
                <Button variant="link" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Cost
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            let cost: number = row.getValue("total_cost");
            return <div className="lowercase px-4">{cost.toFixed(2)}</div>;
        }
    },
    {
        id: "chart",
        enableHiding: false,
        cell: ({ row }) => {
            let [htmlFileString, setHtmlFileString] = useState<string>();
            const [isLoading, setIsLoading] = useState<boolean>(false);
            const [dialogOpen, setDialogOpen] = useState<boolean>(false);
            const client = useMsal().instance;
            // only show the chart if the file has been processed
            const handleGraphAPI = async (fileName: string) => {
                setHtmlFileString("");
                const idToken = await getToken(client);
                if (!idToken) {
                    throw new Error("Token not found");
                }
                const response = await tokenGraphApi(fileName, idToken);
                // let graphHTML = response.graph.replace('window.open(this.href.baseVal,this.target.baseVal,"[^"]+");return false;/g;', "");

                setHtmlFileString(response.graph);
            };
            const executeScripts = (html: string) => {
                const div = document.createElement("div");
                div.innerHTML = html;
                const scripts = div.getElementsByTagName("script");
                for (let i = 0; i < scripts.length; i++) {
                    const script = document.createElement("script");
                    script.type = scripts[i].type || "text/javascript";
                    if (scripts[i].src) {
                        script.src = scripts[i].src;
                    } else {
                        script.innerHTML = scripts[i].innerHTML;
                    }
                    document.head.appendChild(script);
                }
            };
            // Loading state checking for open dialog and htmlString
            useEffect(() => {
                if (htmlFileString) {
                    executeScripts(htmlFileString);
                }
            }, [htmlFileString]);
            const handleTitleName = (fileName: string) => {
                fileName = fileName.replace("Product_description_files/", "");
                return fileName;
            };
            return (
                <Dialog>
                    <DialogTrigger asChild>
                        {row.getValue("status") === "success" ? (
                            <Button
                                variant="link"
                                className="h-6 w-6 p-0"
                                onClick={() => {
                                    let fileName = row.getValue("name") as string;
                                    fileName = fileName.replace("Product_description_files/", "");
                                    handleGraphAPI(fileName);
                                }}
                            >
                                Open chart
                            </Button>
                        ) : null}
                    </DialogTrigger>
                    <DialogContent className="max-w-5xl">
                        <DialogHeader>
                            <DialogTitle>Chart for {handleTitleName(row.getValue("name"))}</DialogTitle>
                        </DialogHeader>
                        {htmlFileString !== "" ? (
                            <div
                                dangerouslySetInnerHTML={{ __html: htmlFileString ? htmlFileString : "" }}
                                className="cursor-pointer w-full bg-primary-text "
                            ></div>
                        ) : (
                            <div className="flex justify-center items-center h-64">
                                <svg
                                    className="animate-spin  h-1/4 w-1/4 text-primary-text "
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
            );
        }
    },
    {
        id: "actions",
        enableHiding: false,
        cell: ({ row }) => {
            const { handleRemoveFile, handleDownloadFile, handleGetDescription } = useFileHandler();
            const dataContext = useContext(DataContext);
            if (!dataContext) {
                throw new Error("DataContext must be used within a DataProvider");
            }

            const { deletionStatus, setChoose, isUploading } = dataContext;
            return (
                <>
                    {deletionStatus === row.getValue("name") ? (
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-text " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="link" className="h-6 w-6 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <MoreHorizontal className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuLabel>Actions</DropdownMenuLabel>

                                {row.getValue("status") === "not initiated" && (
                                    <DropdownMenuItem
                                        onClick={() => {
                                            let fileName = row.getValue("name") as string;
                                            setChoose(row.getValue("name") as string);
                                            fileName = fileName.replace("Product_description_files/", "");
                                            handleGetDescription(fileName, "True", "True", "NL", [""]);
                                        }}
                                        className="hover:bg-[#f4f4f5] cursor-pointer"
                                        disabled={isUploading}
                                    >
                                        <p className="flex-grow">Probe</p>
                                        <FileInput size={16}></FileInput>
                                    </DropdownMenuItem>
                                )}
                                <DropdownMenuItem onClick={() => handleDownloadFile(row.getValue("name"))} className="hover:bg-[#f4f4f5] cursor-pointer">
                                    <p className="flex-grow">Download</p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        className={"outline-0 stroke-primary-text group-hover:stroke-primary-text fill-none stroke-2"}
                                    >
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>

                                        <polyline points="7 10 12 15 17 10"></polyline>
                                        <line x1="12" y1="15" x2="12" y2="3"></line>
                                    </svg>
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    onClick={() => handleRemoveFile(row.getValue("name"), "True", "True", "True")}
                                    className="hover:bg-[#f4f4f5] cursor-pointer"
                                >
                                    <p className="flex-grow text-red-600">Delete</p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="red"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className=""
                                    >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </>
            );
        }
    }
];

export function DataTable() {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [rowSelection, setRowSelection] = React.useState({});
    const [pagination, setPagination] = React.useState({ pageIndex: 0, pageSize: 5 });
    const { instance } = useMsal();
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("DataContext must be used within a DataProvider");
    }

    const { progressStatus } = dataContext;

    const data: ProgressStatus[] = progressStatus;
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
            pagination
        }
    });

    return (
        <div className="w-full">
            <div className="flex items-center py-4">
                <Input
                    placeholder="Filter names..."
                    value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
                    onChange={event => table.getColumn("name")?.setFilterValue(event.target.value)}
                    className="max-w-sm"
                />
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length && isLoggedIn(instance) ? (
                            table.getRowModel().rows.map(row => (
                                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                                    {row.getVisibleCells().map(cell => (
                                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-between space-x-2 py-4">
                <div className="">
                    <span className="text-sm text-gray-500">
                        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()} results
                    </span>
                </div>
                <div className="space-x-2 flex flex-row gap-1">
                    <Button
                        variant="outline"
                        className="flex items-center justify-center"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <ChevronLeft className="h-4 w-4"></ChevronLeft>
                        Previous
                    </Button>
                    <Button
                        variant="outline"
                        className="flex justify-center items-center"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        Next
                        <ChevronRight className="h-4 w-4"></ChevronRight>
                    </Button>
                </div>
            </div>
        </div>
    );
}
