interface ArrowIndicatorProps {
    isVisible: boolean;
}
export const ArrowIndicator = ({ isVisible }: ArrowIndicatorProps) => {
    return (
        <div className={`flex flex-row absolute left-1/2 bottom-1 z-10 transform -translate-x-1/2 -translate-y-1/2 ${isVisible ? "visible" : "invisible"}`}>
            <div className="h-1 w-3 bg-primary transform translate-x-0.5 rotate-[20deg] rounded"></div>
            <div className="h-1 w-3 bg-primary transform -translate-x-0.5 -rotate-[20deg] rounded"></div>
        </div>
    );
};
