import * as React from "react";

import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { bottomOfContainer, getFlagEmoji, isOverflown } from "@/lib/utils";
import { ChevronsUpDown } from "lucide-react";
import { ColumnsMatchingData, MatchedData } from "@/api";
import { DataContext } from "@/context/dataContext";
import { useFileHandler } from "@/lib/hooks";
import { useLayoutEffect, useRef, useState } from "react";
import { ArrowIndicator } from "../ArrowIndicator";
interface SelectorProps {
    languageSelector: boolean;
    label: keyof MatchedData;
    columns?: ColumnsMatchingData;
    setColumns?: React.Dispatch<React.SetStateAction<ColumnsMatchingData | undefined>> | undefined;
    selectedLanguage?: string;
    setSelectedLanguage?: React.Dispatch<React.SetStateAction<string | undefined>> | undefined;
}

export const Selector: React.FC<SelectorProps> = ({ languageSelector, label, columns, setColumns, selectedLanguage, setSelectedLanguage }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const dataContext = React.useContext(DataContext);
    if (!dataContext) {
        throw new Error("Data Context must be used within a DataProvider");
    }
    const { handleGetDescription } = useFileHandler();
    const { languages, choose, fileData, isProcessing, isUploading } = dataContext;
    const [selectedColumn, setSelectedColumn] = React.useState<string | undefined>(columns?.matched[label]);
    const containerRef = React.useRef<HTMLDivElement>(null);
    if (!languageSelector) {
        return (
            <div ref={containerRef}>
                <Popover open={open} onOpenChange={setOpen} modal={true}>
                    <PopoverTrigger asChild>
                        <Button className="w-full justify-between">
                            <p className="text-sm truncate w-[90%]">{selectedColumn ? <>{selectedColumn}</> : "Select a Column"}</p>

                            <ChevronsUpDown size={16} className=""></ChevronsUpDown>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        className="p-0 w-[200px]"
                        align="start"
                        style={{
                            width: 250 + "px"
                        }}
                        container={containerRef.current}
                    >
                        <ColumnList
                            setOpen={setOpen}
                            setSelectedColumn={setSelectedColumn}
                            columns={columns}
                            label={label}
                            setColumns={setColumns}
                        ></ColumnList>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }
    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild>
                <Button
                    className="w-[200px] justify-start"
                    disabled={isProcessing || isUploading || (!fileData.has("file") && choose === null) || languages.length === 0}
                >
                    <p className="flex-grow text-sm">{selectedLanguage ? <>{selectedLanguage}</> : "Select a Language"}</p>
                    <ChevronsUpDown size={16}></ChevronsUpDown>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0" align="start">
                <LanguageList
                    setOpen={setOpen}
                    setSelectedLanguage={setSelectedLanguage}
                    languages={languages}
                    handleGetDescription={handleGetDescription}
                    fileData={fileData}
                    choose={choose}
                ></LanguageList>
            </PopoverContent>
        </Popover>
    );
};
function ColumnList({
    setOpen,
    setSelectedColumn,
    columns,
    label,
    setColumns
}: {
    setOpen: (open: boolean) => void;
    setSelectedColumn: (selectedColumn: string | undefined) => void;
    columns: ColumnsMatchingData | undefined;
    setColumns: React.Dispatch<React.SetStateAction<ColumnsMatchingData | undefined>> | undefined;
    label: keyof MatchedData;
}) {
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        // Take target to get whats inside UIEvent type and then we have to get HTMLDivElement type so its not any
        // FIXME: Its always true so have to fix it
        const target = e.target as HTMLDivElement;
        // We get the scrollHeight which is the height of the content then we take out the clientHeight which the content we see that is not overflow and then we take out how much we need to be at the top
        const bottom = bottomOfContainer(target);
        if (bottom) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };
    // We are using layout effect because we want to measure the height before we paint the container to check to show the arrow or not
    useLayoutEffect(() => {
        if (!containerRef.current) return;
        if (containerRef.current.scrollHeight === containerRef.current.clientHeight) {
            setIsVisible(false);
        }
    }, [containerRef]);
    return (
        <Command>
            <CommandList>
                <CommandGroup onScroll={handleScroll} ref={containerRef}>
                    {columns?.all_columns.map(column => (
                        <CommandItem
                            key={column}
                            value={column}
                            onSelect={value => {
                                setSelectedColumn(value || undefined);
                                if (setColumns) {
                                    setColumns(prevState => {
                                        if (!prevState) return prevState; // Handle the undefined case

                                        return {
                                            ...prevState,
                                            matched: {
                                                ...prevState.matched,
                                                [label]: value
                                            },
                                            all_columns: prevState.all_columns ?? [] // Ensure all_columns is defined as an array
                                        };
                                    });
                                }
                                setOpen(false);
                            }}
                        >
                            <p className="flex-grow">{column}</p>
                        </CommandItem>
                    ))}
                </CommandGroup>
                {/* The reason we have the translate and transform because of the anchor point */}
                <ArrowIndicator isVisible={isVisible}></ArrowIndicator>
            </CommandList>
        </Command>
    );
}
function LanguageList({
    languages,
    setOpen,
    setSelectedLanguage,
    handleGetDescription,
    fileData,
    choose
}: {
    setOpen: (open: boolean) => void;
    setSelectedLanguage: React.Dispatch<React.SetStateAction<string | undefined>> | undefined;
    languages: Array<string>;
    handleGetDescription: (
        fileName: string,
        statistics: string,
        probe: string,
        language: string,
        languages: Array<string>,
        fetch_languages?: string
    ) => Promise<any>;
    fileData: FormData;
    choose: string | null;
}) {
    return (
        <Command>
            <CommandList>
                <CommandGroup>
                    {languages.map(language => (
                        <CommandItem
                            key={language}
                            value={language}
                            onSelect={value => {
                                const selectedLang = languages.find(lang => lang === value);
                                if (!setSelectedLanguage) return;
                                setSelectedLanguage(selectedLang);
                                if (selectedLang) {
                                    if (fileData.has("file")) {
                                        const file = fileData.get("file") as File;
                                        handleGetDescription(file.name, "False", "True", selectedLang, [""]);
                                    } else if (choose !== null) {
                                        handleGetDescription(choose, "False", "True", selectedLang, [""]);
                                    }
                                }

                                setOpen(false);
                            }}
                        >
                            <p className="flex-grow">{language}</p>
                            <div className="ml-auto h-4 w-4">{getFlagEmoji(language)}</div>
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    );
}
