import React, { FC, useContext, useEffect, useState } from "react";
import { MultiLanguageSelector } from "./Selectors/MultiLanguageSelector";
import { Euro } from "lucide-react";
import { handleTime } from "@/lib/utils";
import { CostInfo } from "@/api";

interface OptimizedLanguageStepProps {
    error: string;
    selectedLanguages: string[];
    setSelectedLanguages: React.Dispatch<React.SetStateAction<string[]>>;
    costInfo: CostInfo | undefined;
}
export const OptimizedLanguageStep = ({ selectedLanguages, setSelectedLanguages, costInfo }: OptimizedLanguageStepProps) => {
    let price = costInfo?.total_cost;
    if (!price) return;
    price = price * selectedLanguages.length;
    return (
        <div className="w-full flex flex-col items-center h-full">
            <ul>
                <li className="flex flex-row gap-1 items-center">
                    Cost: {price.toFixed(2) ?? 0 * selectedLanguages.length}
                    <Euro size={16} color="#0661fe"></Euro>
                </li>
                <li>Estimate Time: {handleTime(costInfo?.total_time)}</li>
            </ul>
            <MultiLanguageSelector setSelectedLanguages={setSelectedLanguages} selectedLanguages={selectedLanguages}></MultiLanguageSelector>
        </div>
    );
};
