import React, { ChangeEvent, FormEvent, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { columnsMatchingApi, descriptionProcessApi, descriptionUploadApi, languagesApi, processApi, userStatsApi } from "../api";
import { getToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

import { motion, useAnimation } from "framer-motion";
import { isLoggedIn, requireAccessControl } from "../authConfig";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "@/components/ui/dialog";
import { DataContext } from "../context/dataContext";
import { useToast } from "@/components/ui/use-toast";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "@/components/ui/alert-dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ChevronRight, Euro, X } from "lucide-react";
import { QuestionInput } from "@/components/QuestionInput";
import ParseDescription from "@/components/ParseDescription";
import { useFetchProgress, useFileHandler } from "@/lib/hooks";
import { debounce } from "lodash";
import { CostChart } from "@/components/Charts";
import { ColumnsMatchingData, CostChartData, ProgressStatus, UserStats } from "../api/models";
import { ChartConfig } from "@/components/ui/chart";
import { bottomOfContainer, handleTime, isOverflown } from "@/lib/utils";
import { Selector } from "@/components/Selectors/Selector";
import { UploadStep } from "@/components/MultiSteps/UploadStep";
import { OptimizedLanguageStep } from "@/components/OptimizedLanguageStep";
import { ColumnsMatching } from "@/components/MultiSteps/ColumnsMatching";
import { setLanguage } from "@fluentui/react";
import { ArrowIndicator } from "@/components/ArrowIndicator";
const chartConfig = {
    cost: {
        label: "Cost",
        color: "#0661fe"
    }
} satisfies ChartConfig;

const handleMonthNow = () => {
    const date = new Date();
    const month = new Intl.DateTimeFormat("en-NL", { month: "long" }).format(date);
    return month;
};
function Product(): JSX.Element {
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [isOriginalArrowVisible, setIsOriginalArrowVisible] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const dataContext = useContext(DataContext);
    const originalContainer = useRef<HTMLDivElement>(null);
    const optimizedContainer = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<string>("");
    const [dialogCollapsed, setDialogCollapsed] = useState<boolean>(false);
    const [userStats, setUserStats] = useState<UserStats>();
    const { handleRemoveFile, handleGetDescription, handleGetStatistics, handleLanguageApi } = useFileHandler();
    const { handleProgress } = useFetchProgress();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOptimizedArrowVisible, setIsOptimizedArrowVisible] = useState<boolean>(false);
    if (!dataContext) {
        throw new Error("Data Context must be used within a DataProvider");
    }

    const {
        fileData,
        setFileData,
        isProcessing,
        setIsProcessing,
        costInfo,
        descriptionInfo,
        choose,
        setChoose,
        progressStatus,
        isUploading,
        setIsUploading,
        setProgressStatus,
        isDuplicate,
        setIsDuplicate,
        columns,
        setColumns,
        lastChunk,
        languages,
        setLanguages,
        selectedLanguage,
        setSelectedLanguage,
        selectedLanguages,
        setSelectedLanguages
    } = dataContext;

    const client = useMsal().instance;
    const { toast } = useToast();
    const { instance } = useMsal();

    // TODO: useMemo for the function
    const handleAlreadyProcessed = (fileData: FormData, progressStatus: ProgressStatus[]): boolean => {
        if (fileData.has("file")) {
            const file = fileData.get("file") as File;
            let fileName = file.name;
            fileName = "Product_description_files/" + fileName;
            if (Array.isArray(progressStatus) && progressStatus.some(({ name, status }) => name === fileName && status === "success")) {
                return false;
            }
        }

        return true;
    };
    const handleDuplicateUpload = (file: File) => {
        let name = "Product_description_files/" + file.name;
        return progressStatus.some(file => file.name === name);
    };
    const handleUserStats = async () => {
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            const response = await userStatsApi(idToken);
            console.log(response.money_spent);
            setUserStats(response);
        } catch {
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: "Error getting user stats. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
        }
    };
    useEffect(() => {
        if (isOverflown(originalContainer.current)) {
            setIsOriginalArrowVisible(true);
        } else {
            setIsOriginalArrowVisible(false);
        }
        if (isOverflown(optimizedContainer.current)) {
            setIsOptimizedArrowVisible(true);
        } else {
            setIsOptimizedArrowVisible(false);
        }
    }, [descriptionInfo]);
    const uploadFile = async (file: File) => {
        setIsUploading(true);
        setChoose(null);
        const formData = new FormData();
        formData.append("file", file);
        setFileData(formData);
        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            let fileName = "Product_description_files/" + file.name;

            await descriptionUploadApi(formData, idToken);
            const stats = await handleGetStatistics(fileName);
            if (stats) {
                setProgressStatus(prevState => [
                    ...prevState,
                    {
                        name: fileName,
                        status: "not initiated",
                        total_cost: stats?.total_cost,
                        total_time: stats?.total_time,
                        number_of_rows: stats?.number_of_rows,
                        number_of_processed: undefined,
                        languages: [],
                        batches: []
                    }
                ]);
            }

            setError("");
            // TODO: Don't call handleGetDescription here but after sumbit and also don't close the dialog
            //await handleGetDescription(file.name, "True", "True", ["original"]);
            setIsUploading(false);
            setUploaded(true);
            setLanguages([]);
            toast({
                variant: "success",
                title: "File Uploaded Successfully",
                description: "Your file has been uploaded and is now available for use."
            });
        } catch (errorMessage) {
            console.error("Error uploading file:", errorMessage);
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: "Error uploading file. If you haven't refresh the page in the past 24 hours, please refresh it"
            });
            setIsUploading(false);
        }
    };
    useEffect(() => {
        if (isLoggedIn(instance)) {
            handleUserStats();
        }
    }, [isProcessing, isUploading]);
    const handleUploadFile = () => async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const file: File = e.target.files[0];
        if (handleDuplicateUpload(file)) {
            setDialogCollapsed(false);
            setIsDuplicate(true);
            const formData = new FormData();
            formData.append("file", file);
            setFileData(formData);
        } else {
            await uploadFile(file);
        }
    };

    // Processes all of the rows in the file
    const handleProcessAll = async (formData: FormData) => {
        setIsProcessing(true);

        try {
            const idToken = await getToken(client);
            if (!idToken) {
                throw new Error("No authentication token available");
            }
            console.log("Processing");
            setProgressStatus(prevState => {
                return prevState.map(file => {
                    return {
                        ...file,
                        languages: selectedLanguages
                    };
                });
            });
            if (choose) {
                // if not initialized it might just break
                let fileName = choose.replace("Product_description_files/", "");
                const response = await processApi(fileName, idToken, selectedLanguages.join(","));
                console.log(response);
                await handleProgress(
                    fileName,
                    "/description/progress?" +
                        new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False", languages: selectedLanguages?.join(",") })
                );
            } else {
                const file = formData.get("file") as File;
                const fileName = file.name;
                await processApi(fileName, idToken, selectedLanguages.join(","));
                await handleProgress(
                    fileName,
                    "/description/progress?" +
                        new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False", languages: selectedLanguages?.join(",") })
                );
            }

            console.log("Stopped Processing");
            setLanguages([]);
        } catch (error: unknown) {
            console.error("Error processing data:", error);
            if (error instanceof Error) {
                toast({
                    variant: "destructive",
                    title: "Uh oh! Something went wrong.",
                    description: error.message
                });
            }
        }
    };

    // upload file and append to formData which passed to the end point
    // FIXME: Only show file name after sumbit
    const handleTitleName = () => {
        if (!isDuplicate) {
            let fileName;
            if (fileData.has("file")) {
                const file = fileData.get("file") as File;
                fileName = file.name.replace(".csv", "");
                return `Selected File: ${fileName}`;
            } else if (choose !== null) {
                fileName = choose.replace("Product_description_files/", "");
                fileName = fileName.replace(".csv", "");
                return `Selected File: ${fileName}`;
            }
            return "No selected file";
        }
        return "No selected file";
    };
    const handleGetColumnsMatching = async (fileName: string) => {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        const response = (await columnsMatchingApi(fileName, idToken, "GET", undefined)) as ColumnsMatchingData;
        setColumns(response);
    };
    const handlePutColumnsMatching = async (fileName: string) => {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        await columnsMatchingApi(fileName, idToken, "PUT", columns);
    };

    const handleNext = debounce(async () => {
        if (currentStep === 0) {
            if (fileData.has("file")) {
                const file = fileData.get("file") as File;
                await handleGetColumnsMatching(file.name);
                setCurrentStep(i => i + 1);
            }
        }
    }, 1500);
    const handleSumbit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (fileData.has("file")) {
            const file = fileData.get("file") as File;

            if (currentStep === 0 && file !== null) {
                await handleGetColumnsMatching(file.name);

                setCurrentStep(i => i + 1);
            } else if (currentStep === 1 && columns) {
                await handlePutColumnsMatching(file.name);
                setSelectedLanguage("NL");
                setDialogCollapsed(false);
                setUploaded(false);
                setSelectedLanguages([]);
                await handleGetDescription(file.name, "True", "True", "NL");
                setCurrentStep(0);
                setColumns(undefined);
                const languages = await handleLanguageApi(file.name);
                setLanguages(languages);
            } else if (currentStep >= 3) {
                setCurrentStep(2);
            }
        } else {
            setError("You have to upload a file to continue");
        }
    };
    return (
        <div className="w-full h-full">
            <div className="p-12 flex flex-col w-full h-full gap-6 ">
                <div className="flex flex-row justify-between items-center">
                    <div className="text-primary-text font-semibold text-base w-full  truncate">{handleTitleName()}</div>
                    <Dialog>
                        <DialogTrigger asChild onClick={() => handleUserStats()}>
                            <div className="w-full flex justify-center items-center">
                                <div className="w-56 rounded-md h-full border  cursor-pointer shadow flex items-center justify-start p-2">
                                    <div className="flex items-center justify-between w-[50%]">
                                        <p className="text-primary-text font-medium text-xs ">Monthly spent: </p>
                                    </div>
                                    {isLoggedIn(instance) && (
                                        <div className="font-bold text-xl text-primary-text flex items-center justify-center ml-1 w-[40%]">
                                            {userStats ? (
                                                userStats?.money_spent.find(item => item.month === handleMonthNow())?.cost.toFixed(2) + "€"
                                            ) : (
                                                <svg
                                                    className="animate-spin self-center h-5 w-5 text-primary-text"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            )}
                                        </div>
                                    )}

                                    <ChevronRight className="w-4 h-4 ml-auto w-[10%]"></ChevronRight>
                                </div>
                            </div>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Total Cost/Month</DialogTitle>
                                <DialogDescription>Here you can see the chart of the amount spent each month.</DialogDescription>
                            </DialogHeader>
                            {userStats?.money_spent.every(item => item.cost === 0) ? (
                                <div>You didn't spend money yet</div>
                            ) : (
                                <CostChart chartData={userStats ? userStats?.money_spent : undefined} chartConfig={chartConfig}></CostChart>
                            )}
                        </DialogContent>
                    </Dialog>

                    <Dialog open={dialogCollapsed}>
                        <DialogTrigger
                            onClick={() => {
                                if (!isProcessing || !isUploading) setDialogCollapsed(true);
                            }}
                            className="w-full flex justify-end items-center"
                        >
                            <Button disabled={isUploading || isProcessing}>Upload Files</Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogPrimitive.Close
                                onClick={() => setDialogCollapsed(false)}
                                className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                            >
                                <X className="h-4 w-4" />
                                <span className="sr-only">Close</span>
                            </DialogPrimitive.Close>

                            <form encType="multipart/form-data" onSubmit={handleSumbit}>
                                {/* Show the file input only if no files are selected */}
                                {currentStep === 0 && (
                                    <UploadStep
                                        isProcessing={isProcessing}
                                        isUploading={isUploading}
                                        handleUploadFile={handleUploadFile}
                                        error={error}
                                        uploaded={uploaded}
                                    ></UploadStep>
                                )}
                                {currentStep === 1 && <ColumnsMatching columns={columns} setColumns={setColumns} error={error}></ColumnsMatching>}
                                <div className="flex w-full flex-row justify-between items-center">
                                    <Button
                                        onClick={() => {
                                            setError("");
                                            setCurrentStep(i => i - 1);
                                        }}
                                        type="button"
                                        className={`${currentStep === 0 || !uploaded ? "invisible" : "visible"}`}
                                    >
                                        Go back
                                    </Button>
                                    <Button
                                        type={`${currentStep === 1 ? "submit" : "button"}`}
                                        disabled={isUploading || isProcessing || !fileData.has("file") || !uploaded}
                                        onClick={() => {
                                            currentStep === 0 && handleNext();
                                        }}
                                        ref={buttonRef}
                                    >
                                        {currentStep === 1 ? "Submit" : "Next"}
                                    </Button>
                                </div>
                            </form>
                        </DialogContent>
                    </Dialog>
                    <AlertDialog open={isDuplicate}>
                        {handleAlreadyProcessed(fileData, progressStatus) ? (
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                    <AlertDialogDescription>Are you sure you want to upload a duplicate file?</AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogCancel
                                    onClick={() => {
                                        setFileData(new FormData());
                                        setIsDuplicate(false);
                                        setDialogCollapsed(true);
                                    }}
                                >
                                    Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction
                                    onClick={async () => {
                                        const handleFile = async () => {
                                            const file = fileData.get("file") as File;
                                            if (file) {
                                                setDialogCollapsed(true);
                                                let fileName = "Product_description_files/" + file.name;
                                                await handleRemoveFile(fileName, "True", "True", "True");
                                                setProgressStatus(prevState => {
                                                    return prevState.filter(file => file.name !== fileName);
                                                });

                                                await uploadFile(file);
                                            }
                                        };
                                        setIsUploading(true);
                                        setIsDuplicate(false);

                                        try {
                                            await handleFile();
                                        } catch (error) {
                                            console.error("Error handling duplicate file upload");
                                            setIsDuplicate(false);
                                        }
                                    }}
                                >
                                    Confirm
                                </AlertDialogAction>
                            </AlertDialogContent>
                        ) : (
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle className="flex flex-row gap-2">
                                        File Upload Error: Processed counterpart detected
                                        <AlertDialogCancel
                                            onClick={() => {
                                                setFileData(new FormData());
                                                setIsDuplicate(false);
                                                setDialogCollapsed(true);
                                            }}
                                            className="ml-auto"
                                        >
                                            <X className="h-2 w-2" />
                                        </AlertDialogCancel>
                                    </AlertDialogTitle>
                                    <AlertDialogDescription>
                                        The file you are attempting to upload has already been processed. To avoid accidental loss of your processed files,
                                        which may have involved financial investment, we do not allow the upload of duplicate files with processed counterparts.
                                        Please delete the existing processed file before re-uploading the duplicate file.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                            </AlertDialogContent>
                        )}
                    </AlertDialog>
                </div>

                <div className="flex flex-row justify-center gap-8 mb-4 h-full">
                    <div className="w-[50%] transition  ease-in-out duration-500 ">
                        <div className={` bg-[#E4EAFF] h-full rounded-md relative`}>
                            <div
                                className="space-y-2 mx-4 pt-8 max-h-[600px] overflow-y-auto "
                                ref={originalContainer}
                                onScroll={() => {
                                    setIsOriginalArrowVisible(isOverflown(originalContainer.current) && !bottomOfContainer(originalContainer.current));
                                }}
                            >
                                <h1 className="text-primary-text font-semibold text-xl">Original Description:</h1>
                                {descriptionInfo && descriptionInfo.original ? (
                                    <ParseDescription result={descriptionInfo.original}></ParseDescription>
                                ) : (
                                    // <h2 className="text-primary-text text-base">{descriptionInfo.original}</h2>
                                    <div className="flex flex-col gap-2">
                                        <Skeleton className="h-8 w-full" />
                                        <Skeleton className="h-4 w-[33%]" />
                                        {isUploading && (
                                            <div className="flex justify-center items-center mt-12">
                                                <svg
                                                    className="animate-spin  h-1/4 w-1/4 text-white "
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <ArrowIndicator isVisible={isOriginalArrowVisible}></ArrowIndicator>
                        </div>
                    </div>
                    {/* TODO: Rewrite as mapping is not needed anymore and index based checking */}
                    {Array.from({ length: 1 }).map((_, index) => (
                        <div className=" w-[50%] ">
                            <div className={`group-hover:rounded-b-md rounded-md bg-[#E4EAFF] h-full relative`}>
                                <div
                                    className="space-y-2 mx-4 pt-8 max-h-[600px] overflow-y-auto "
                                    ref={optimizedContainer}
                                    onScroll={() => {
                                        setIsOptimizedArrowVisible(isOverflown(optimizedContainer.current) && !bottomOfContainer(optimizedContainer.current));
                                    }}
                                >
                                    <h1 className="text-primary-text font-semibold text-xl">
                                        {index === 0 ? "Optimized Description:" : "Prompted Description:"}
                                    </h1>
                                    {/*When I upload a file the getDescription new description shows up*/}
                                    {index === 0 && descriptionInfo && descriptionInfo.result ? (
                                        <div>
                                            {lastChunk ? (
                                                <ParseDescription result={descriptionInfo.result}></ParseDescription>
                                            ) : (
                                                <p>{descriptionInfo?.result}</p>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex flex-col gap-2">
                                            <Skeleton className="h-8 w-full" />
                                            <Skeleton className="h-4 w-[33%]" />
                                            {isUploading && index === 0 && (
                                                <div className="flex justify-center items-center mt-12">
                                                    <svg
                                                        className="animate-spin  h-1/4 w-1/4 text-white "
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <ArrowIndicator isVisible={isOptimizedArrowVisible}></ArrowIndicator>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-row items-center justify-center gap-8 ">
                    <div className="w-1/2 flex justify-center items-center">
                        <Button
                            className="gap-2"
                            onClick={() => {
                                if (fileData.has("file")) {
                                    const file = fileData.get("file") as File;
                                    handleGetDescription(file.name, "False", "True", selectedLanguage);
                                } else if (choose) {
                                    let fileName = choose.replace("Product_description_files/", "");
                                    handleGetDescription(fileName, "False", "True", selectedLanguage);
                                }
                                setIsDuplicate(false);
                            }}
                            disabled={isUploading || (!fileData.has("file") && choose === null) || isProcessing || languages.length === 0}
                        >
                            {isUploading ? (
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="f"
                                >
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <polyline points="1 20 1 14 7 14"></polyline>
                                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                                </svg>
                            )}
                            <p>Regenerate</p>
                        </Button>
                    </div>
                    <div className="w-1/2 flex justify-center items-center">
                        <Selector
                            setSelectedLanguage={setSelectedLanguage}
                            selectedLanguage={selectedLanguage}
                            languageSelector={true}
                            label="price"
                        ></Selector>
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button disabled={isUploading || ((!fileData.has("file") || uploaded) && choose === null) || isProcessing}>
                                {isUploading || isProcessing ? (
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white "
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                ) : null}
                                <p>Process File</p>
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>This will start the processing of the files.</AlertDialogDescription>
                            </AlertDialogHeader>
                            <OptimizedLanguageStep
                                selectedLanguages={selectedLanguages}
                                setSelectedLanguages={setSelectedLanguages}
                                error={error}
                                costInfo={costInfo}
                            ></OptimizedLanguageStep>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                    onClick={() => {
                                        handleProcessAll(fileData);
                                    }}
                                    disabled={selectedLanguages.length === 0}
                                >
                                    Continue
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            </div>
        </div>
    );
}

export default Product;
