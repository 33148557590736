import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig, useLogin } from "./authConfig";
import "./index.css";
import { DataProvider } from "./context/dataContext";
import Layout from "./pages/layout/Layout";
import Product from "./pages/Product";
import { Toaster } from "@/components/ui/toaster";
var layout;
if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <Layout />
            <Toaster></Toaster>
        </MsalProvider>
    );
} else {
    layout = <Layout />;
}

initializeIcons();
const RedirectHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to Chat page if not already there
        if (window.location.pathname !== "/") {
            navigate("/");
        }
    }, [navigate]);

    return null;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <>
                <RedirectHandler />
                {layout}
            </>
        ),
        children: [
            {
                index: true,
                element: <Product />
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <DataProvider>
            <RouterProvider router={router} />
        </DataProvider>
    </React.StrictMode>
);
