import { handleFileColor } from "@/lib/utils";
import { FC, useContext, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { Button } from "../ui/button";
import { CostInfo, ProgressStatus } from "../../api/models";
import { DataContext } from "@/context/dataContext";
import { useFileHandler, useFetchProgress } from "@/lib/hooks";
import { toast } from "../ui/use-toast";
import { handleTime } from "@/lib/utils";
interface ProcessedFilesProps {
    handleDownloadFile: Function;
    file: ProgressStatus;
    handleRemoveProgress: (fileName: string) => boolean;
    handlePopover: (fileName: string) => boolean;
    handleRemoveFile: (filename: string, server: string, local: string, database: string) => Promise<void>;
    handleFileName: (fileName: string) => string | undefined;
    handleGetStatistics: (fileName: string) => Promise<CostInfo | undefined>;
    collapsed: string | null;
    setCollapsed: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ProcessedFiles = ({
    file,
    handleRemoveFile,
    handleDownloadFile,
    handleRemoveProgress,
    handlePopover,
    handleFileName,
    handleGetStatistics,
    collapsed,
    setCollapsed
}: ProcessedFilesProps) => {
    const { handleRetry } = useFileHandler();
    const { handleProgress } = useFetchProgress();
    const dataContext = useContext(DataContext);
    if (!dataContext) {
        throw new Error("DataContext must be used within a DataProvider");
    }

    const { isProcessing, isUploading, progressStatus, setChoose, setIsProcessing } = dataContext;

    const handleFileContent = (fileName: string) => {
        switch (handleFileColor(fileName)) {
            case "border-green-600":
                return (
                    <>
                        <div className="flex flex-col items-center justify-center">
                            <ul>
                                {progressStatus !== undefined &&
                                    // have to find the file we clicked on and then display the data
                                    progressStatus.map(
                                        file =>
                                            file.name === fileName && (
                                                <div>
                                                    {/* <li>Number of Products: {processedStatistics.number_of_rows.toFixed(2)}</li> */}
                                                    <h2>Total Cost: {file.total_cost.toFixed(2)}</h2>
                                                    <h2>Total Time: {handleTime(file.total_time)}</h2>

                                                    {/* <li>Total Time: {processedStatistics.total_time.toFixed(2)}</li>
                                <li>Cost per item: {processedStatistics.total_cost_one_item.toFixed(2)}</li> */}
                                                </div>
                                            )
                                    )}
                            </ul>
                            <div className="flex flex-row items-center mt-4 gap-2">
                                <Button onClick={() => handleDownloadFile(fileName)}>Download</Button>
                            </div>
                        </div>
                    </>
                );
                break;
            case "border-yellow-400":
                return (
                    <>
                        <div className="flex flex-col gap items-center justify-center">
                            {progressStatus !== undefined &&
                                progressStatus.map(
                                    file =>
                                        file.name === fileName && (
                                            <div className="">
                                                <div className="flex flex-col items-center mt-4 gap-4">
                                                    <div>
                                                        {/* <li>Number of Products: {processedStatistics.number_of_rows.toFixed(2)}</li> */}
                                                        <h2>Total Cost: {file.total_cost.toFixed(2)}</h2>
                                                        <h2>Total Time: {handleTime(file.total_time)}</h2>
                                                        {/* <li>Total Time: {processedStatistics.total_time.toFixed(2)}</li>
                                <li>Cost per item: {processedStatistics.total_cost_one_item.toFixed(2)}</li> */}
                                                    </div>
                                                    <div className="flex flex-row gap-2">
                                                        {file.batches && file.batches.some(batch => batch.output_file_processed === true) && (
                                                            <Button onClick={() => handleDownloadFile(file.name)}>Download</Button>
                                                        )}
                                                        <Button
                                                            disabled={isProcessing || isUploading}
                                                            onClick={async () => {
                                                                setIsProcessing(true);
                                                                const languages = file.languages;
                                                                await handleRemoveFile(file.name, "False", "True", "True");
                                                                let fileName = file.name.replace("Product_description_files/", "");
                                                                const stats = await handleGetStatistics(fileName);
                                                                if (stats) {
                                                                    await handleRetry(
                                                                        fileName,
                                                                        stats.total_cost,
                                                                        stats.total_time,
                                                                        stats.number_of_rows,
                                                                        languages
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {isProcessing ? (
                                                                <div className="flex flex-row items-center">
                                                                    <svg
                                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white "
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <circle
                                                                            className="opacity-25"
                                                                            cx="12"
                                                                            cy="12"
                                                                            r="10"
                                                                            stroke="currentColor"
                                                                            stroke-width="4"
                                                                        ></circle>
                                                                        <path
                                                                            className="opacity-75"
                                                                            fill="currentColor"
                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                        ></path>
                                                                    </svg>
                                                                    <p className="cursor-not-allowed">Processing</p>
                                                                </div>
                                                            ) : (
                                                                <p>Retry</p>
                                                            )}
                                                        </Button>
                                                        <Button
                                                            disabled={isProcessing || isUploading}
                                                            onClick={() => {
                                                                let fileName = file.name.replace("Product_description_files/", "");
                                                                handleProgress(
                                                                    fileName,
                                                                    "/description?" +
                                                                        new URLSearchParams({ file_name: fileName, statistics: "False", probe: "False" })
                                                                );
                                                            }}
                                                        >
                                                            {isProcessing ? (
                                                                <div className="flex flex-row items-center">
                                                                    <svg
                                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white "
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <circle
                                                                            className="opacity-25"
                                                                            cx="12"
                                                                            cy="12"
                                                                            r="10"
                                                                            stroke="currentColor"
                                                                            stroke-width="4"
                                                                        ></circle>
                                                                        <path
                                                                            className="opacity-75"
                                                                            fill="currentColor"
                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                        ></path>
                                                                    </svg>
                                                                    <p className="cursor-not-allowed">Processing</p>
                                                                </div>
                                                            ) : (
                                                                <p>Resume</p>
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )}
                        </div>
                    </>
                );
                break;
            case "border-red-500":
                return (
                    <>
                        {progressStatus !== undefined &&
                            progressStatus.map(
                                file =>
                                    file.name === fileName && (
                                        <div>
                                            {/* Processed Items: {file.processed} */}
                                            <div className="flex flex-col items-center mt-4 gap-2">
                                                <div>
                                                    {/* <li>Number of Products: {processedStatistics.number_of_rows.toFixed(2)}</li> */}
                                                    <h2>Total Cost: {file.total_cost.toFixed(2)}</h2>
                                                    <h2>Total Time: {handleTime(file.total_time)}</h2>
                                                    {/* <li>Total Time: {processedStatistics.total_time.toFixed(2)}</li>
                                <li>Cost per item: {processedStatistics.total_cost_one_item.toFixed(2)}</li> */}
                                                </div>
                                                {file.batches && file.batches.some(batch => batch.output_file_processed === true) && (
                                                    <Button onClick={() => handleDownloadFile(file.name)}>Download</Button>
                                                )}
                                                <Button
                                                    disabled={isProcessing || isUploading}
                                                    onClick={async () => {
                                                        setIsProcessing(true);
                                                        const languages = file.languages;
                                                        await handleRemoveFile(file.name, "False", "True", "True");
                                                        let fileName = file.name.replace("Product_description_files/", "");
                                                        const stats = await handleGetStatistics(fileName);
                                                        if (stats) {
                                                            await handleRetry(fileName, stats.total_cost, stats.total_time, stats.number_of_rows, languages);
                                                        }
                                                    }}
                                                >
                                                    {isProcessing ? (
                                                        <div className="flex flex-row items-center">
                                                            <svg
                                                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white "
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    stroke-width="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                ></path>
                                                            </svg>
                                                            <p className="cursor-not-allowed">Processing</p>
                                                        </div>
                                                    ) : (
                                                        <p>Retry</p>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    )
                            )}
                    </>
                );
                break;
        }
    };
    return (
        <Dialog>
            <div
                className={`group w-full relative border-2 rounded-lg bg-secondary-text hover:bg-[#e8e8e8] cursor-pointer p-2 flex flex-col gap-2 items-center ${handleFileColor(
                    file.name
                )}`}
                key={file.name}
            >
                <div className="flex flex-row items-center w-full justify-center">
                    <DialogTrigger className="flex-grow max-w-[90%]" asChild disabled={handleRemoveProgress(file.name)}>
                        <a className="flex items-center justify-center text-primary-text">
                            <div className="relative overflow-hidden whitespace-nowrap text-sm truncate">{handleFileName(file.name)}</div>
                        </a>
                    </DialogTrigger>

                    <Popover open={handlePopover(file.name)} onOpenChange={() => setCollapsed(collapsed === file.name ? null : file.name)} modal={true}>
                        <PopoverTrigger disabled={handleRemoveProgress(file.name)}>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild disabled={handleRemoveProgress(file.name)}>
                                        {handleRemoveProgress(file.name) ? (
                                            <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-text"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                className="feather feather-more-vertical stroke-primary-text fill-none"
                                            >
                                                <circle cx="12" cy="12" r="1"></circle>
                                                <circle cx="12" cy="5" r="1"></circle>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </svg>
                                        )}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {handleRemoveProgress(file.name) ? (
                                            <p className="text-primary-text">Removing</p>
                                        ) : (
                                            <p className="text-primary-text">Options</p>
                                        )}
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </PopoverTrigger>
                        <PopoverContent className="w-32 p-1">
                            <div className="px-2 py-1.5 text-sm font-semibold">Actions</div>
                            {handleFileColor(file.name) !== "border-red-500" &&
                                file.batches &&
                                file.batches.some(batch => batch.output_file_processed === true) && (
                                    <div
                                        className="relative cursor-pointer hover:bg-[#f4f4f5] flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                                        onClick={() => {
                                            handleDownloadFile(file.name);
                                            setCollapsed(null);
                                        }}
                                    >
                                        <p className="flex-grow">Download</p>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            className={"outline-0 stroke-primary-text group-hover:stroke-primary-text fill-none stroke-2"}
                                        >
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>

                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                    </div>
                                )}

                            <div
                                className="relative cursor-pointer hover:bg-[#f4f4f5]  flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                                onClick={() => {
                                    handleRemoveFile(file.name, "True", "True", "True");
                                    setCollapsed(null);
                                }}
                            >
                                <p className="flex-grow text-red-600">Delete</p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className=""
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="capitalize">File Name: {handleFileName(file.name)}</DialogTitle>
                    <DialogDescription>Here you can see all the information about the processed file</DialogDescription>
                </DialogHeader>
                {handleFileContent(file.name)}
            </DialogContent>
        </Dialog>
    );
};
