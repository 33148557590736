import React, { FC, useState } from "react";

import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from "@azure/msal-react";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronDown, LogOut } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useLogin, isLoggedIn, loginRequest, getRedirectUri, appServicesLogout, appServicesToken } from "../../authConfig";

export const LoginButton = (): JSX.Element => {
    const [collapsed, setCollapsed] = useState(false);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const client = useLogin ? useMsal().instance : undefined;
    const userName = `${
        activeAccount?.name ? activeAccount.name.split(" ")[0] : activeAccount?.username.split("@")[0] ?? appServicesToken?.user_claims?.preferred_username
    }`;
    /*return (
        <DefaultButton
            text={isLoggedIn ? logoutText : "Login"}
            className={styles.loginButton}
            onClick={isLoggedIn ? handleLogoutPopup : handleLoginPopup}
        ></DefaultButton>
    );*/
    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri(),
                prompt: "login"
            })
            .then(response => {
                // Login successful, refresh the page
                window.location.reload();
            })
            .catch(error => console.log(error));
    };
    const handleLogoutPopup = () => {
        if (activeAccount) {
            instance
                .logoutPopup({
                    mainWindowRedirectUri: "/", // redirects the top level app after logout
                    account: instance.getActiveAccount()
                })
                .catch(error => console.log(error));
        } else {
            appServicesLogout();
        }
    };
    return (
        <div className="w-[100px] flex items-center justify-center">
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <div className="h-[100px] mt-16 w-full">
                        <Collapsible open={collapsed} onOpenChange={setCollapsed} className="w-[100px]">
                            <div className="group flex items-center  justify-center w-24 rounded-md space-x-4 group hover:bg-primary hover:text-secondary-text">
                                <h4 className="text-sm font-semibold text-primary-text  transition transition-colors group-hover:text-secondary-text">
                                    {userName.split(".", 2)[0]}
                                </h4>
                                <CollapsibleTrigger asChild>
                                    <Button size="sm" className="w-5 bg-transparent p-0 hover:bg-transparent">
                                        <ChevronDown className="h-5 w-5 group-hover:text-secondary-text text-primary-text"></ChevronDown>
                                        <span className="sr-only">Toggle</span>
                                    </Button>
                                </CollapsibleTrigger>
                            </div>
                            <CollapsibleContent className="items-center mt-1 h-[150px] w-[100px] self-center">
                                <nav className="bg-primary flex flex-col gap-2 rounded-md p-1">
                                    <a
                                        className="flex gap-2  group rounded hover:bg-primary p-1 hover:text-secondary-text text-sm cursor-pointer items-center text-primary-text"
                                        onClick={handleLogoutPopup}
                                    >
                                        <LogOut className="outline-0 fill-none stroke-primary-text h-4 w-4 group-hover:stroke-secondary-text"></LogOut>
                                        Logout
                                    </a>
                                </nav>
                            </CollapsibleContent>
                        </Collapsible>
                        {/*<DefaultButton text={logoutText} className="text-bold bg-background border-0" onClick={handleLogoutPopup}></DefaultButton>*/}
                    </div>
                ) : null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="flex items-center justify-center">
                    <Button size={"lg"} onClick={handleLoginPopup}>
                        Login
                    </Button>
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
};
